<template>
  <div class="end">
    <div class="end-box-sta">
      <div class="meun">
        <el-menu
          :default-active="index.toString()"
          background-color="#fff"
          text-color="#333"
          active-text-color="#fff"
        >
          <el-menu-item
            @click="menuItemClick(index)"
            v-for="(list, index) in leftList"
            :key="index"
            :index="index.toString()"
          >
            <span slot="title">{{ list.label }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="content">
        <div id="scrollIntoView" class="box" v-if="index === 0">
          <div class="content-title">小美客服系统隐私政策</div>
          <div class="little margin-title">生效日期：2023年3月1日</div>
          <div class="little margin-title">特别说明：</div>
          <div class="content-text">
            <div class="short-space"></div>
            欢迎使用由大连市小美网络科技技术有限公司及其关联公司（以下统称为“我们”或“小美客服系统”）提供的产品和/或服务（以下统称为“小美客服系统服务”）。我们一向高度重视个人信息的保护。在您使用小美客服系统服务时，您作为我们的客户，我们可能会收集和使用您的相关个人信息；同时，
            <span class="bold">
              您作为控制者决定您的产品和服务的最终用户（以下简称为“最终用户”）个人信息的处理目的和方式，我们在为您提供小美客服系统服务过程中作为数据处理者，接受您的委托并根据您指示处理最终用户的相关个人信息。
            </span>
            我们希望通过本《小美客服系统隐私政策》（以下简称为“本政策”）向您和最终用户清晰地介绍我们对您及最终用户的个人信息的处理方式，以便更好地保障您和最终用户的权益。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            本政策适用于各项小美客服系统服务，包括但不限于我们提供的网站、SDK、API、插件、组件、代码、工具以及不断创新研发的产品及服务。因此，
            <span class="bold">
              在使用小美客服系统服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后使用我们的相关产品或服务；在最终用户使用您的产品和服务前，请您务必制定、发布您的隐私类政策文件并获得您的最终用户同意以使小美客服系统有权处理其个人信息，且该政策文件应包含本政策关于最终用户个人信息的处理规则以及不低于本政策规定的个人信息保护标准。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            如果您不同意本政策的内容或者您的最终用户不同意小美客服系统根据本政策处理其个人信息，您应立即停止使用小美客服系统服务。您使用或继续使用小美客服系统服务，都表示您和最终用户同意我们按照本政策（包括更新版本）收集、使用、储存、分享、转让和披露您及最终用户的个人信息；如果因您未事先获得最终用户同意而引起的相关责任由您完全承担。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            需要特别说明的是，在您的个人信息处理方面，《小美客服系统隐私政策》与本政策同时对您产生效力，本政策与《小美客服系统隐私政策》存在不一致的，本政策优先适用，如本政策中未提及的，则以《小美客服系统隐私政策》规定为准。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            在阅读完本政策后，如您和最终用户对本政策或与本政策相关的事宜有任何问题，可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快作出解答。
          </div>
          <div class="little margin-title">本政策将帮助您了解以下内容：</div>
          <div class="content-text">
            <div class="short-space"></div>
            一、我们如何收集和使用您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            二、最终用户个人信息的授权与使用
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            三、我们如何共享、转让、公开披露您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            四、我们如何存储您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            五、如何保护您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            六、我们如何管理您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            七、我们如何处理未成年人的信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            八、修订和通知
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            九、如何联系我们
          </div>
          <div class="little margin-title" id="show1">
            一、 我们如何收集和使用您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.1
            我们会依据相关法律法规，遵循正当、合法、必要的原则，出于本政策所述的目的，收集和使用您在使用小美客服系统服务过程中主动提供的、因您使用小美客服系统服务而产生的以及因您的最终用户使用您的服务和/或产品而产生的您和/或最终用户的个人信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.2
            请您理解，由于商业策略的变化及法律法规的相关要求，小美客服系统服务的业务功能也可能进行更迭和发展。
            <span class="bold">
              如果我们要将您和/或最终用户的个人信息用于本政策未载明的其他用途或额外收集未提及的其他个人信息，我们会通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。
            </span>
            在此过程中，您可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快作出解答。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.3 您作为我们的客户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.3.1
            <span class="bold">
              为完成小美客服系统服务账户的注册、登录、管理和实名认证等必要活动，您可能需要提交真实、合法、有效的信息。
            </span>
            您可能需要提交的信息包括：您
            <span class="bold">基本的账号信息，</span>
            包括
            <span class="bold">用户名、手机号、邮箱地址、账号密码；</span>
            您的
            <span class="bold">身份信息，</span>
            包括
            <span class="bold">
              您的姓名、身份证号、身份证正反面照片、包含您个人图像的手持身份证照片；
            </span>
            您的
            <span class="bold">产品信息</span>
            ，包括您的
            <span class="bold">产品名称、行业分类；</span>
            您的
            <span class="bold">联系人信息</span>
            ，包括
            <span class="bold"> 联系人姓名、联系手机号及QQ号； </span>
            您的
            <span class="bold">企业信息:</span>
            包括
            <span class="bold">
              企业名称、统一社会信用代码、法定代表人身份证正反面照片。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.3.2
            为实现实名认证的目的，您同意并授权我们可能自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              1.3.3
              为保障小美客服系统服务的正常运行，保护您或第三方的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或小美客服系统服务相关协议规则的情况，
            </span>
            我们在您使用小美客服系统服务期间，将记录并保存您
            <span class="bold">登录和使用小美客服系统服务的详细情况，</span>
            并作为
            <span class="bold">网络日志</span>
            进行保存，
            <span class="bold">
              包括
              Internet协议(IP)地址、Internet服务提供商（ISP）、浏览器类型、访问日期和时间、软硬件特征信息及网页浏览记录。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.3.4
            我们收集这些信息是为了向您更好提供服务，为了实现这一目的，我们还将把您的信息用于下列用途：
            <span class="bold">
              （1）联系您解决问题；（2）改进我们的服务质量；（3）经您许可的其他用途。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.4 您的最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            基于您的委托和指示，为实现小美客服系统服务的基本业务功能，我们可能需要收集、使用您的最终用户的相关个人信息。小美客服系统服务包括但不限于在线客服、智能客服（智能机器人）、呼叫中心等服务。
            <span class="bold">
              请您注意，仅部分小美客服系统服务需要收集、使用最终用户的个人信息且根据您选择的小美客服系统服务不同，我们收集的最终用户个人信息也将不同。以下将详细列出小美客服系统服务需收集、使用最终用户个人信息的基本业务功能及为实现该功能所必需的信息类型，这些个人信息的收集、使用需要您获得最终用户的同意。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.5.1 小美客服系统在线客服服务
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            “小美客服系统在线客服服务”包括IM即时通讯服务、信令服务，为您提供文字，语音，图片等形式的客服服务能力，通过该服务您可以将在线客服能力快速集成至自身产品和服务中并为您的最终用户提供在线客服服务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.1
            <span class="bold">
              为提供消息投递、多端消息同步、消息漫游功能，
            </span>
            我们需对最终用户进行唯一标识，我们需获取最终用户的
            <span class="bold">设备信息相关权限，</span>
            并收集和使用最终用户的
            <span class="bold">
              唯一设备识别码（Mac地址/APNS Token/IMEI）。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.2
            <span class="bold"> 为提供发送本地文件消息功能， </span>
            我们需获取最终用户的
            <span class="bold">设备存储权限。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.3
            <span class="bold">为提供发送音频消息功能，</span>
            我们需获取最终用户的
            <span class="bold">设备麦克风权限，</span>
            并收集和发送最终用户的
            <span class="bold">语音信息。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.4
            <span class="bold">为提供发送图片和视频消息功能，</span>
            我们需获取最终用户的
            <span class="bold">摄像头和相册权限，</span>
            并收集和发送最终用户的
            <span class="bold">图片和视频信息。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.5
            <span class="bold">
              为进行运行环境判断，以便于您排查小美客服系统在线客服服务运行中出现的问题，
            </span>
            我们将收集最终用户的下列信息，包括（1）
            <span class="bold">设备信息：</span>
            设备品牌及型号、设备所运行系统版本、名称、编辑版本信息；（2）
            <span class="bold"> 日志信息： </span>
            浏览器类型及版本信息、网络连接类型及状态、云信通信服务运行日志信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.6
            <span class="bold">
              为保障您的账户安全，保证小美客服系统在线客服服务仅在经您授权的应用中使用，
            </span>
            我们将在最终用户的设备收集您开发的
            <span class="bold">IOS应用的Bundle ID</span>
            及/或您开发的
            <span class="bold">Android应用的应用包名。</span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.6请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.7征得授权同意的例外
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            根据相关法律法规规定，以下情形中处理您及最终用户的信息无需征得您的同意：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.1 为订立、履行个人作为一方当事人的合同所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.2 为履行法定职责或者法定义务所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.3
            为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.4
            为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.5
            依照法律法规规定在合理的范围内处理您和最终用户自行公开或者其他已经合法公开的个人信息；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.6 法律法规规定的其他情形。
          </div>
          <div class="little margin-title">
            二、最终用户的个人信息授权与使用
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.1
            您作为最终用户的个人信息控制者，我们接受您的委托并根据您指示为您提供数据处理服务，但无法控制您如何使用最终用户的个人信息。您应当采用符合适用的法律和道德义务的方式收集、使用并授权我们收集、使用您的最终用户的个人信息。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2
            如果您接受本政策并在您的产品和服务中使用小美客服系统服务，您需保证您已经遵守并将持续遵守适用的法律、法规和监管要求和本政策的规定，包括但不限于：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2.1 将遵守向最终用户收集信息有关的所有适用的法律、法规和监管要求；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              2.2.2
              必须向最终用户披露自身的产品和服务中使用了小美客服系统服务，以及我们如何收集和使用最终用户个人信息的规则并获得最终用户充分、必要且明确的授权同意，允许我们在符合相关法律法规的前提下可将收集的信息用于本政策列明的服务用途。
            </span>
            为履行上述义务，您应当制定、发布不低于本政策规定的个人信息保护标准的隐私类政策文件。我们也强烈建议您在您的隐私类政策文件中嵌入本政策的链接并增加类似条款：“我们的产品或服务可能包括第三方的产品或服务，也可能收集并使用您的个人信息。例如，我们为实现【
            】服务集成小美客服系统SDK，小美客服系统将作为数据处理者，按照本公司的指示收集处理您的数据，小美客服系统将按照《小美客服系统隐私政策》（附链接）所述收集、使用并保护您的个人信息》”；
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.3
            已经向最终用户提供易于操作的权利实现和信息管理机制，说明用户如何行使选择权、如何访问、更正其个人信息、行使删除权、更改其授权同意的范围等操作。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2.4
            如果您无法满足前述条件或者您的最终用户不同意小美客服系统根据本政策收集、使用其个人信息的，则您不应当继续使用小美客服系统服务；您的继续使用行为，视为您已经同意并向我们保证您所有的最终用户已同意我们有权收集和使用其个人信息提供相应服务。无论如何，如因您未能事先获得最终用户同意以使我们有权收集并使用其个人信息提供服务而引起的一切责任由您承担，如给我们造成任何损失，您应予以全部赔偿。
          </div>
          <div class="little margin-title">
            三、我们如何共享、转让、公开披露您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1 共享
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们将按照《小美客服系统隐私政策》的约定，共享您的个人信息。除下列情况外，我们不会与任何第三方共享您最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1.1
            获得您的同意或您委托我们向您指定的第三方转让最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.2 根据法律法规的规定、强制性的行政执法或司法要求；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              3.1.3
              只有共享您最终用户的信息，才能提供服务，或处理您最终用户与他人的纠纷或争议。
            </span>
            我们会与共享的合作伙伴签署严格的保密协定，要求他们按照本政策以及采取严格的数据安全措施来处理和使用数据。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              3.1.4
              与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能。
            </span>
            我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            目前，我们委托的授权合作伙伴包括以下类型：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.1
            广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.2
            供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务或进行学术研究和调查。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.3
            为保障小美客服系统服务的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1.4.4 您可点击《第三方SDK目录详情》，查看我们接入的SDK具体情况。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.2 转让
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们仅会在以下情况，转让您或最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.2.1
            获得您的同意或您委托我们向您指定的第三方转让最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.2.2
            随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您及最终用户的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您及最终用户个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新征求授权同意。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.3 公开披露
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们仅会在以下情况，公开披露您或最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.3.1
            获得您的同意或您委托我们披露最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.3.2
            根据法律法规的规定、强制性的行政执法或司法要求所必须提供相关信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您或最终用户的个人信息。
          </div>
          <div class="little margin-title">
            四、 我们如何存储您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.1 存储时限
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              除法律法规或监管部门另有规定，我们仅在本政策所述目的所必需期间且最短时限内储存您及最终用户的个人信息。
            </span>
            如我们终止服务或运营，我们将及时停止继续收集个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对相关个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.2 存储地域
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              原则上，我们仅在中国境内存储您及最终用户的个人信息。但在以下情况，我们可能向中国境外提供您及最终用户的
            </span>
            个人信息，或者在中国境外存储您及最终用户的个人信息：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.2.1 法律法规有明确规定；
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.2.2 获得您的同意，但前提是您确保已获得最终用户的同意。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.3我们请您特别注意：不同国家或地区对于个人信息的收集、存储、使用、共享等各有其监管要求，
            <span class="bold">
              您应主动遵守各个国家或地区的法律法规和监管要求。
            </span>
            为了避免您由于不了解您的最终用户所在国或所在地区关于个人信息保护相关法律法规而触犯当地的监管规定，
            <span class="bold">
              我们强烈建议您仅在中国境内使用小美客服系统服务。如您将小美客服系统服务应用于中国境外的，您应当遵循当地有关个人信息跨境传输转移的法律要求，以保证在中国境内存储个人信息符合当地的监管要求。
            </span>
            特别是，
            <span class="bold">
              当跨境传输涉及到俄罗斯、印度、欧盟、美国等国家或地区时，请注意这些国家和地区的个人信息监管规定。
            </span>
            您同意，跨境传输产生的风险和责任将由您自行承担，如导致我们发生任何形式的损失，您负责给予我们全额赔偿。
          </div>
          <div class="little margin-title">
            五、 我们如何保护您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1
            我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您及最终用户的个人信息：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.1
            我们会采用符合业界标准的安全技术措施，包括采取加密等技术对您及最终用户的个人信息进行加密保存，并通过隔离技术进行隔离，以防止您及最终用户的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.2
            我们有行业先进的以个人信息为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理等方面多维度提升整个系统的安全性。我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.3
            我们仅允许有必要知晓这些信息的人员访问您及最终用户的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您及最终用户的个人信息的所有人员履行相应的保密义务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.4
            我们重视信息安全合规工作，并通过众多国际和国内的安全认证，如ISO27001信息安全管理体系认证、信息系统安全等级保护认证等，以业界先进的解决方案充分保障您及最终用户的信息安全。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.5
            为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.2
            请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.3
            您需要了解，您使用小美客服系统服务所用的系统和通讯网络等，有可能因我们可控范围外的情况而发生问题。
            <span class="bold">
              为更有效的保障您及最终用户的信息安全，我们也希望您能够加强自我保护，如果因您的物理、技术、或管理防护设施遭到破坏，导致您及最终用户的个人信息被非授权访问、公开披露、篡改、或毁坏，由此产生的风险和责任将由您自行承担，我们将不承担责任。
            </span>
          </div>
          <div class="little margin-title">
            六、 如何管理您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1 管理您的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.1 访问、更新和删除
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.1.1.1
            我们鼓励您更新和修改您的个人信息以使其更准确有效。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和删除自身的个人信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              6.1.1.2
              如您想查询、修改或删除您的部分个人信息，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以行使您的权利，但法律法规另有规定的除外。
            </span>
            在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.2 账号注销
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们向您提供帐号注销的途径。
            <span class="bold">
              在符合我们的服务条款约定条件及相关法律法规规定的情况下，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以注销您的小美客服系统账户。
            </span>
            在您注销账号时，我们可能会要求您进行身份验证，以保障信息安全。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.3 改变您授权同意的范围
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            您总是可以选择是否向我们披露个人信息。有些个人信息是使用小美客服系统服务所必需的，对于额外收集的个人信息，您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4 删除、注销及撤回授权可能产生的影响
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4.1
            当您删除某项个人信息或撤回某项授权后，我们将无法继续为您提供所对应的服务，也不再处理您相应的个人信息；当您注销账号后，我们将停止为您提供全部小美客服系统服务，并依据您的要求，删除您的个人信息或做匿名化处理，但法律法规另有规定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4.2
            无论如何，您做出的上述决定，不会影响此前基于您的授权而开展的个人信息处理，由此造成您无法继续使用全部或部分小美客服系统服务的，相应后果和损失由您自行承担，我们将依据我们的服务条款和相应规则做出处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.5 个人信息副本获取
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            如您需要您的个人信息的副本，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息副本，但法律法规另有规定的或本政策另有约定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            无论如何，您做出的上述决定，不会影响此前基于您的授权而开展的个人信息处理，由此造成您无法继续使用全部或部分小美客服系统服务的，相应后果和损失由您自行承担，我们将依据我们的服务条款和相应规则做出处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.2 管理最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.2.1 鉴于您的最终用户不是小美客服系统的直接客户，
            <span class="bold">
              您应当遵循适用的法律、法规和监管要求和本政策的规定，为最终用户提供易于操作的方式以响应最终用户访问、更新、删除其个人信息、注销账号、更改其授权同意的范围、获取个人信息副本等请求。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.2.2 如果您在操作过程中有疑惑或困难且必需我们配合才能完成的，
            <span class="bold">
              您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以行使您的权利，
            </span>
            我们将根据您的委托、法律法规和本政策的规定进行处理。
            <span class="bold">
              如果最终用户选择直接向小美客服系统提出相关请求的，为了保护您、最终用户以及他人的合法权益，我们会要求该用户进行身份的验证。在向您进行核实后，我们有权根据法律法规以及本政策的规定处理该用户的相关请求。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.2.3
            无论如何，作为最终用户的数据控制者，您都应当承担最终用户的请求响应和处理，如因您的原因给我们造成任何损失，您应予以全部赔偿。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.3 响应您及最终用户的上述请求
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            为保障安全，您及最终用户可能需要提供书面请求，或以其他方式证明身份。我们可能会先要求您及最终用户验证自己的身份，然后再处理请求。对于您及最终用户合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些与我们无关、无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            在以下情形中，我们将无法响应您及最终用户的请求：
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.1 与我们履行法律法规规定的义务相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.2 与国家安全、国防安全直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.3 与公共安全、公共卫生、重大公共利益直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.4 与刑事侦查、起诉、审判和执行判决等直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.5 我们有充分证据表明您或最终用户存在主观恶意或滥用权利的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.6
            出于维护您、最终用户或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.7
            响应您及最终用户的请求将导致您、最终用户或其他个人、组织的合法权益受到严重损害的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.8 涉及商业秘密的。
          </div>
          <div class="little margin-title">七、 我们如何处理未成年人的信息</div>
          <div class="content-text">
            <div class="short-space"></div>
            7.1
            我们非常重视未成年人的个人信息保护，如您是未成年人，请勿注册成为我们的用户并使用小美客服系统服务。
            <span class="bold">
              如您的产品和/或服务涉及未成年用户，请您务必遵守可适用的法律、法规和监管要求，并采取必要的措施确保未成年人的注册、使用与未成年用户个人信息的收集、使用均已获得其监护人的同意，同时，您应在您的隐私类政策文件中履行相应的告知义务。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            7.2
            受制于现有技术和业务模式，我们很难主动识别未成年人的信息。如果我们在不知情的情况下收集了未成年人的个人信息或在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，监护人可通知我们予以删除，如我们自己发现前述情形，我们也会主动予以删除而无需征得您的同意，除非法律要求我们保留此类资料。
          </div>
          <div class="little margin-title">八、 修订和通知</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            8.1
            为了给您提供更好的服务，我们可能会根据小美客服系统服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。我们会在本页面上发布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知（如网站公告、推送通知、弹窗提示），本政策所指的重大变更包括但不限于：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.1
            小美客服系统服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.2
            我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.3 个人信息共享、转让或公开披露的主要对象发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.4
            您及最终用户参与个人信息处理方面的权利及其行使方式发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.5
            我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.6 其他重要的或可能严重影响您及最终用户的个人权益的情况发生。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            我们的任何修改都会将您及最终用户的满意度置于首位。我们鼓励您在每次使用服务时都查阅我们的隐私政策。
          </div>
          <div class="little margin-title">九、如何联系我们</div>
          <div class="content-text">
            <div class="short-space"></div>
            9.1
            我们设立了个人信息保护专职部门，将按照本政策保护您及最终用户的个人信息。如您和最终用户有关小美客服系统服务信息安全的投诉和举报，或对本政策、隐私措施、您及最终用户的个人信息的相关事宜有任何问题、意见或建议，
            <span class="bold">
              请与小美客服系统的个人信息保护工作人员联系，可发送邮件至xiaomeikeji@mail.chat5188.com，邮件标题：
              个人信息保护专员（收）
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            9.2
            一般情况下，我们将在收到相关问题、意见或建议，并验证身份后的十五个工作日内予以回复。
            如您和最终用户不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
          </div>
        </div>
        <div class="box" v-if="index === 1">
          <div class="content-title">小美客服系统SDK隐私政策</div>
          <div class="little margin-title">引言</div>
          <div class="content-text">
            <div class="short-space"></div>
            欢迎使用由大连市小美网络科技技术有限公司及其关联公司（以下统称为“我们”或“小美客服系统”）提供的小美客服系统SDK产品（以下简称为“小美客服系统SDK”或“该SDK”），小美客服系统SDK是一款提供包括IM即时通讯服务、信令服务，为您提供文字，语音，图片，工单等形式的客服服务能力，通过该服务您可以将在线客服能力快速集成至自身产品和服务中并为您的最终用户提供在线客服服务的产品。我们一向高度重视个人信息的保护。我们希望通过《小美客服系统隐私政策》（以下简称为“本政策”）向您和最终用户清晰地介绍我们对您及最终用户的个人信息的处理方式，以便更好地保障您和最终用户的权益。本政策主要向您和您的最终用户说明我们如何处理个人信息（处理包括收集、存储、使用、加工、传输、提供、公开等）。
            <span class="bold">
              在注册、接入、使用该
              SDK产品和/或服务前，请您和您的最终用户务必仔细阅读本政策。同时，请您将本政策提供给您的最终用户，以便最终用户了解本政策相关内容并获得用户同意。如果您或您的最终用户不同意本政策，应立即停止接入及使用该SDK产品和/或服务。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              我们对涉及个人信息权益保护的重要条款采用“加粗”的书写方式进行特别提醒。
            </span>
            如对本政策内容有任何疑问、意见或建议，可随时通过本政策第九条提供的联系方式与我们联系。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            需要特别说明的是，在处理您的个人信息之事项方面，《小美客服系统隐私政策》与本政策同时对您产生效力，本政策与《小美客服系统隐私政策》存在不一致的，本政策优先适用，如本政策中未提及的，则以《小美客服系统隐私政策》规定为准。
          </div>
          <div class="little margin-title">本政策将帮助您了解以下内容：</div>
          <div class="content-text">
            <div class="short-space"></div>
            一、我们如何收集和使用您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            二、最终用户个人信息的授权与使用
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            三、我们如何共享、转让、公开披露您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            四、我们如何存储您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            五、如何保护您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            六、我们如何管理您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            七、我们如何处理未成年人的信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            八、修订和通知
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            九、如何联系我们
          </div>
          <div class="little margin-title">
            一、 我们如何收集和使用您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.1
            我们会依据相关法律法规，遵循正当、合法、必要的原则，出于本政策所述的目的，收集和使用您在使用小美客服系统服务过程中主动提供的、因您使用小美客服系统服务而产生的以及因您的最终用户使用您的服务和/或产品而产生的您和/或最终用户的个人信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.2
            请您理解，由于商业策略的变化及法律法规的相关要求，小美客服系统服务的业务功能也可能进行更迭和发展。
            <span class="bold">
              如果我们要将您和/或最终用户的个人信息用于本政策未载明的其他用途或额外收集未提及的其他个人信息，我们会通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。
            </span>
            在此过程中，您可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快作出解答。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.3 您作为我们的客户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.3.1
            <span class="bold">
              为完成小美客服系统服务账户的注册、登录、管理和实名认证等必要活动，您可能需要提交真实、合法、有效的信息。
            </span>
            您可能需要提交的信息包括：您
            <span class="bold">基本的账号信息，</span>
            包括
            <span class="bold">用户名、手机号、邮箱地址、账号密码；</span>
            您的
            <span class="bold">身份信息，</span>
            包括
            <span class="bold">
              您的姓名、身份证号、身份证正反面照片、包含您个人图像的手持身份证照片；
            </span>
            您的
            <span class="bold">产品信息</span>
            ，包括您的
            <span class="bold">产品名称、行业分类；</span>
            您的
            <span class="bold">联系人信息</span>
            ，包括
            <span class="bold"> 联系人姓名、联系手机号及QQ号； </span>
            您的
            <span class="bold">企业信息:</span>
            包括
            <span class="bold">
              企业名称、统一社会信用代码、法定代表人身份证正反面照片。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.3.2
            为实现实名认证的目的，您同意并授权我们可能自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              1.3.3
              为保障小美客服系统服务的正常运行，保护您或第三方的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或小美客服系统服务相关协议规则的情况，
            </span>
            我们在您使用小美客服系统服务期间，将记录并保存您
            <span class="bold">登录和使用小美客服系统服务的详细情况，</span>
            并作为
            <span class="bold">网络日志</span>
            进行保存，
            <span class="bold">
              包括
              Internet协议(IP)地址、Internet服务提供商（ISP）、浏览器类型、访问日期和时间、软硬件特征信息及网页浏览记录。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.3.4
            我们收集这些信息是为了向您更好提供服务，为了实现这一目的，我们还将把您的信息用于下列用途：
            <span class="bold">
              （1）联系您解决问题；（2）改进我们的服务质量；（3）经您许可的其他用途。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.4 您的最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            基于您的委托和指示，为实现小美客服系统服务的基本业务功能，我们可能需要收集、使用您的最终用户的相关个人信息。小美客服系统服务包括但不限于在线客服、智能客服（智能机器人）、呼叫中心等服务。
            <span class="bold">
              请您注意，仅部分小美客服系统服务需要收集、使用最终用户的个人信息且根据您选择的小美客服系统服务不同，我们收集的最终用户个人信息也将不同。以下将详细列出小美客服系统服务需收集、使用最终用户个人信息的基本业务功能及为实现该功能所必需的信息类型，这些个人信息的收集、使用需要您获得最终用户的同意。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.5.1 小美客服系统在线客服服务
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            “小美客服系统在线客服服务”包括IM即时通讯服务、信令服务，为您提供文字，语音，图片等形式的客服服务能力，通过该服务您可以将在线客服能力快速集成至自身产品和服务中并为您的最终用户提供在线客服服务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.1
            <span class="bold">
              为提供消息投递、多端消息同步、消息漫游功能，
            </span>
            我们需对最终用户进行唯一标识，我们需获取最终用户的
            <span class="bold">设备信息相关权限，</span>
            并收集和使用最终用户的
            <span class="bold">
              唯一设备识别码（Mac地址/APNS Token/IMEI）。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.2
            <span class="bold"> 为提供发送本地文件消息功能， </span>
            我们需获取最终用户的
            <span class="bold">设备存储权限。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.3
            <span class="bold">为提供发送音频消息功能，</span>
            我们需获取最终用户的
            <span class="bold">设备麦克风权限，</span>
            并收集和发送最终用户的
            <span class="bold">语音信息。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.4
            <span class="bold">为提供发送图片和视频消息功能，</span>
            我们需获取最终用户的
            <span class="bold">摄像头和相册权限，</span>
            并收集和发送最终用户的
            <span class="bold">图片和视频信息。</span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.5
            <span class="bold">
              为进行运行环境判断，以便于您排查小美客服系统在线客服服务运行中出现的问题，
            </span>
            我们将收集最终用户的下列信息，包括（1）
            <span class="bold">设备信息：</span>
            设备品牌及型号、设备所运行系统版本、名称、编辑版本信息；（2）
            <span class="bold"> 日志信息： </span>
            浏览器类型及版本信息、网络连接类型及状态、云信通信服务运行日志信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.5.1.6
            <span class="bold">
              为保障您的账户安全，保证小美客服系统在线客服服务仅在经您授权的应用中使用，
            </span>
            我们将在最终用户的设备收集您开发的
            <span class="bold">IOS应用的Bundle ID</span>
            及/或您开发的
            <span class="bold">Android应用的应用包名。</span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.6请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.7征得授权同意的例外
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            根据相关法律法规规定，以下情形中处理您及最终用户的信息无需征得您的同意：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.1 为订立、履行个人作为一方当事人的合同所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.2 为履行法定职责或者法定义务所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.3
            为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.4
            为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.5
            依照法律法规规定在合理的范围内处理您和最终用户自行公开或者其他已经合法公开的个人信息；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.7.6 法律法规规定的其他情形。
          </div>
          <div class="little margin-title">
            二、最终用户的个人信息授权与使用
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.1
            您作为最终用户的个人信息控制者，我们接受您的委托并根据您指示为您提供数据处理服务，但无法控制您如何使用最终用户的个人信息。您应当采用符合适用的法律和道德义务的方式收集、使用并授权我们收集、使用您的最终用户的个人信息。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2
            如果您接受本政策并在您的产品和服务中使用小美客服系统服务，您需保证您已经遵守并将持续遵守适用的法律、法规和监管要求和本政策的规定，包括但不限于：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2.1 将遵守向最终用户收集信息有关的所有适用的法律、法规和监管要求；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              2.2.2
              必须向最终用户披露自身的产品和服务中使用了小美客服系统服务，以及我们如何收集和使用最终用户个人信息的规则并获得最终用户充分、必要且明确的授权同意，允许我们在符合相关法律法规的前提下可将收集的信息用于本政策列明的服务用途。
            </span>
            为履行上述义务，您应当制定、发布不低于本政策规定的个人信息保护标准的隐私类政策文件。我们也强烈建议您在您的隐私类政策文件中嵌入本政策的链接并增加类似条款：“我们的产品或服务可能包括第三方的产品或服务，也可能收集并使用您的个人信息。例如，我们为实现【
            】服务集成小美客服系统SDK，小美客服系统将作为数据处理者，按照本公司的指示收集处理您的数据，小美客服系统将按照《小美客服系统隐私政策》（附链接）所述收集、使用并保护您的个人信息》”；
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.3
            已经向最终用户提供易于操作的权利实现和信息管理机制，说明用户如何行使选择权、如何访问、更正其个人信息、行使删除权、更改其授权同意的范围等操作。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.2.4
            如果您无法满足前述条件或者您的最终用户不同意小美客服系统根据本政策收集、使用其个人信息的，则您不应当继续使用小美客服系统服务；您的继续使用行为，视为您已经同意并向我们保证您所有的最终用户已同意我们有权收集和使用其个人信息提供相应服务。无论如何，如因您未能事先获得最终用户同意以使我们有权收集并使用其个人信息提供服务而引起的一切责任由您承担，如给我们造成任何损失，您应予以全部赔偿。
          </div>
          <div class="little margin-title">
            三、我们如何共享、转让、公开披露您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1 共享
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们将按照《小美客服系统隐私政策》的约定，共享您的个人信息。除下列情况外，我们不会与任何第三方共享您最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1.1
            获得您的同意或您委托我们向您指定的第三方转让最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.2 根据法律法规的规定、强制性的行政执法或司法要求；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              3.1.3
              只有共享您最终用户的信息，才能提供服务，或处理您最终用户与他人的纠纷或争议。
            </span>
            我们会与共享的合作伙伴签署严格的保密协定，要求他们按照本政策以及采取严格的数据安全措施来处理和使用数据。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              3.1.4
              与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能。
            </span>
            我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            目前，我们委托的授权合作伙伴包括以下类型：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.1
            广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.2
            供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务或进行学术研究和调查。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1.4.3
            为保障小美客服系统服务的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.1.4.4 您可点击《第三方SDK目录详情》，查看我们接入的SDK具体情况。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.2 转让
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们仅会在以下情况，转让您或最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.2.1
            获得您的同意或您委托我们向您指定的第三方转让最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.2.2
            随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您及最终用户的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您及最终用户个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新征求授权同意。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.3 公开披露
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们仅会在以下情况，公开披露您或最终用户的个人信息：
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            3.3.1
            获得您的同意或您委托我们披露最终用户的个人信息，但前提是您已获得最终用户的同意；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.3.2
            根据法律法规的规定、强制性的行政执法或司法要求所必须提供相关信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您或最终用户的个人信息。
          </div>
          <div class="little margin-title">
            四、 我们如何存储您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.1 存储时限
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              除法律法规或监管部门另有规定，我们仅在本政策所述目的所必需期间且最短时限内储存您及最终用户的个人信息。
            </span>
            如我们终止服务或运营，我们将及时停止继续收集个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对相关个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.2 存储地域
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              原则上，我们仅在中国境内存储您及最终用户的个人信息。但在以下情况，我们可能向中国境外提供您及最终用户的
            </span>
            个人信息，或者在中国境外存储您及最终用户的个人信息：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.2.1 法律法规有明确规定；
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.2.2 获得您的同意，但前提是您确保已获得最终用户的同意。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.3我们请您特别注意：不同国家或地区对于个人信息的收集、存储、使用、共享等各有其监管要求，
            <span class="bold">
              您应主动遵守各个国家或地区的法律法规和监管要求。
            </span>
            为了避免您由于不了解您的最终用户所在国或所在地区关于个人信息保护相关法律法规而触犯当地的监管规定，
            <span class="bold">
              我们强烈建议您仅在中国境内使用小美客服系统服务。如您将小美客服系统服务应用于中国境外的，您应当遵循当地有关个人信息跨境传输转移的法律要求，以保证在中国境内存储个人信息符合当地的监管要求。
            </span>
            特别是，
            <span class="bold">
              当跨境传输涉及到俄罗斯、印度、欧盟、美国等国家或地区时，请注意这些国家和地区的个人信息监管规定。
            </span>
            您同意，跨境传输产生的风险和责任将由您自行承担，如导致我们发生任何形式的损失，您负责给予我们全额赔偿。
          </div>
          <div class="little margin-title">
            五、 我们如何保护您及最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1
            我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您及最终用户的个人信息：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.1
            我们会采用符合业界标准的安全技术措施，包括采取加密等技术对您及最终用户的个人信息进行加密保存，并通过隔离技术进行隔离，以防止您及最终用户的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.2
            我们有行业先进的以个人信息为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理等方面多维度提升整个系统的安全性。我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.3
            我们仅允许有必要知晓这些信息的人员访问您及最终用户的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您及最终用户的个人信息的所有人员履行相应的保密义务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.4
            我们重视信息安全合规工作，并通过众多国际和国内的安全认证，如ISO27001信息安全管理体系认证、信息系统安全等级保护认证等，以业界先进的解决方案充分保障您及最终用户的信息安全。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1.5
            为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.2
            请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.3
            您需要了解，您使用小美客服系统服务所用的系统和通讯网络等，有可能因我们可控范围外的情况而发生问题。
            <span class="bold">
              为更有效的保障您及最终用户的信息安全，我们也希望您能够加强自我保护，如果因您的物理、技术、或管理防护设施遭到破坏，导致您及最终用户的个人信息被非授权访问、公开披露、篡改、或毁坏，由此产生的风险和责任将由您自行承担，我们将不承担责任。
            </span>
          </div>
          <div class="little margin-title">
            六、 如何管理您及最终用户的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1 管理您的个人信息
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.1 访问、更新和删除
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.1.1.1
            我们鼓励您更新和修改您的个人信息以使其更准确有效。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和删除自身的个人信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            <span class="bold">
              6.1.1.2
              如您想查询、修改或删除您的部分个人信息，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以行使您的权利，但法律法规另有规定的除外。
            </span>
            在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.2 账号注销
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            我们向您提供帐号注销的途径。
            <span class="bold">
              在符合我们的服务条款约定条件及相关法律法规规定的情况下，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以注销您的小美客服系统账户。
            </span>
            在您注销账号时，我们可能会要求您进行身份验证，以保障信息安全。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.3 改变您授权同意的范围
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            您总是可以选择是否向我们披露个人信息。有些个人信息是使用小美客服系统服务所必需的，对于额外收集的个人信息，您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4 删除、注销及撤回授权可能产生的影响
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4.1
            当您删除某项个人信息或撤回某项授权后，我们将无法继续为您提供所对应的服务，也不再处理您相应的个人信息；当您注销账号后，我们将停止为您提供全部小美客服系统服务，并依据您的要求，删除您的个人信息或做匿名化处理，但法律法规另有规定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.4.2
            无论如何，您做出的上述决定，不会影响此前基于您的授权而开展的个人信息处理，由此造成您无法继续使用全部或部分小美客服系统服务的，相应后果和损失由您自行承担，我们将依据我们的服务条款和相应规则做出处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1.5 个人信息副本获取
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            如您需要您的个人信息的副本，您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息副本，但法律法规另有规定的或本政策另有约定的除外。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            无论如何，您做出的上述决定，不会影响此前基于您的授权而开展的个人信息处理，由此造成您无法继续使用全部或部分小美客服系统服务的，相应后果和损失由您自行承担，我们将依据我们的服务条款和相应规则做出处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.2 管理最终用户的个人信息
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.2.1 鉴于您的最终用户不是小美客服系统的直接客户，
            <span class="bold">
              您应当遵循适用的法律、法规和监管要求和本政策的规定，为最终用户提供易于操作的方式以响应最终用户访问、更新、删除其个人信息、注销账号、更改其授权同意的范围、获取个人信息副本等请求。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.2.2 如果您在操作过程中有疑惑或困难且必需我们配合才能完成的，
            <span class="bold">
              您可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们以行使您的权利，
            </span>
            我们将根据您的委托、法律法规和本政策的规定进行处理。
            <span class="bold">
              如果最终用户选择直接向小美客服系统提出相关请求的，为了保护您、最终用户以及他人的合法权益，我们会要求该用户进行身份的验证。在向您进行核实后，我们有权根据法律法规以及本政策的规定处理该用户的相关请求。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.2.3
            无论如何，作为最终用户的数据控制者，您都应当承担最终用户的请求响应和处理，如因您的原因给我们造成任何损失，您应予以全部赔偿。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.3 响应您及最终用户的上述请求
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            为保障安全，您及最终用户可能需要提供书面请求，或以其他方式证明身份。我们可能会先要求您及最终用户验证自己的身份，然后再处理请求。对于您及最终用户合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些与我们无关、无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            在以下情形中，我们将无法响应您及最终用户的请求：
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.1 与我们履行法律法规规定的义务相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.2 与国家安全、国防安全直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.3 与公共安全、公共卫生、重大公共利益直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.4 与刑事侦查、起诉、审判和执行判决等直接相关的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.5 我们有充分证据表明您或最终用户存在主观恶意或滥用权利的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.6
            出于维护您、最终用户或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.7
            响应您及最终用户的请求将导致您、最终用户或其他个人、组织的合法权益受到严重损害的；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            6.3.8 涉及商业秘密的。
          </div>
          <div class="little margin-title">七、 我们如何处理未成年人的信息</div>
          <div class="content-text">
            <div class="short-space"></div>
            7.1
            我们非常重视未成年人的个人信息保护，如您是未成年人，请勿注册成为我们的用户并使用小美客服系统服务。
            <span class="bold">
              如您的产品和/或服务涉及未成年用户，请您务必遵守可适用的法律、法规和监管要求，并采取必要的措施确保未成年人的注册、使用与未成年用户个人信息的收集、使用均已获得其监护人的同意，同时，您应在您的隐私类政策文件中履行相应的告知义务。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            7.2
            受制于现有技术和业务模式，我们很难主动识别未成年人的信息。如果我们在不知情的情况下收集了未成年人的个人信息或在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，监护人可通知我们予以删除，如我们自己发现前述情形，我们也会主动予以删除而无需征得您的同意，除非法律要求我们保留此类资料。
          </div>
          <div class="little margin-title">八、 修订和通知</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            8.1
            为了给您提供更好的服务，我们可能会根据小美客服系统服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。我们会在本页面上发布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知（如网站公告、推送通知、弹窗提示），本政策所指的重大变更包括但不限于：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.1
            小美客服系统服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.2
            我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.3 个人信息共享、转让或公开披露的主要对象发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.4
            您及最终用户参与个人信息处理方面的权利及其行使方式发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.5
            我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.1.6 其他重要的或可能严重影响您及最终用户的个人权益的情况发生。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            我们的任何修改都会将您及最终用户的满意度置于首位。我们鼓励您在每次使用服务时都查阅我们的隐私政策。
          </div>
          <div class="little margin-title">九、如何联系我们</div>
          <div class="content-text">
            <div class="short-space"></div>
            9.1
            我们设立了个人信息保护专职部门，将按照本政策保护您及最终用户的个人信息。如您和最终用户有关小美客服系统服务信息安全的投诉和举报，或对本政策、隐私措施、您及最终用户的个人信息的相关事宜有任何问题、意见或建议，
            <span class="bold">
              请与小美客服系统的个人信息保护工作人员联系，可发送邮件至xiaomeikeji@mail.chat5188.com，邮件标题：
              个人信息保护专员（收）
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            9.2
            一般情况下，我们将在收到相关问题、意见或建议，并验证身份后的十五个工作日内予以回复。
            如您和最终用户不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
          </div>
        </div>
        <div class="box" v-if="index === 2">
          <div class="content-title">小美客服终止服务说明</div>
          <div class="little margin-title">
            第一条
            <div class="shore-space-between"></div>
            终止服务
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.
            乙方在服务期届满前要求终止服务的，一旦终止，甲方将暂停账户使用，且不可恢复使用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2. 甲方可在以下情况下封禁账户
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            a. 账户相关材料缺失；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            b.
            因您自身原因违反与小美客服签署的协议约定（含协议附件约定）、《小美客服服务条款》或您违反国家相关法律规定导致小美客服无法提供服务的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            c. 无正当理由单方面解除与小美客服签署的协议。
          </div>
          <div class="little margin-title">
            第二条
            <div class="shore-space-between"></div>
            不可抗力
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            因不可抗力因素，造成甲方无法继续提供服务，将终止相关服务和协议约定。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftList: [
        { label: "小美客服系统隐私政策" },
        { label: "小美客服系统SDK隐私政策" },
        { label: "小美客服系统终止协议" },
      ],
      index: 0,
    };
  },
  methods: {
    menuItemClick(val) {
      this.index = val;
    },
    // 《小美客服系统终止服务流程规则》
    click1() {
      this.menuItemClick(0);
    },
    // go(value) {
    //   document.getElementById(value).scrollIntoView();
    // },
  },
};
</script>

<style scoped lang="less">
.meun {
  float: left;
  background-color: #304fae;
  height: 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
    height: auto;
  }
}
/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 15px; /* 设置滚动条宽度 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑块背景颜色 */
  border-radius: 4px; /* 设置滑块圆角 */
}

/* 滚动条轨道hover状态样式 */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1; /* 设置轨道hover状态时的背景颜色 */
}

/* 滚动条滑块hover状态样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滑块hover状态时的背景颜色 */
}
.end-box-sta {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  // display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}
.end-box {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}

::v-deep .el-menu {
  border: none;
  padding-left: 0px;
}

::v-deep .el-menu-item {
  height: 70px;
  color: #fff;
  line-height: 70px;
  font-size: 20px;

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

::v-deep .el-menu-item.is-active {
  background-color: #fff !important;
  color: #020f3b;
}

::v-deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #020f3b;
}

.content {
  width: 1080px;
  height: 706px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 3px;
  }
}
.box {
  width: 100%;
  height: 600px;
  overflow: auto;
  padding-left: 20px;
}

.content-title {
  color: #021b64;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (max-width: 700px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.little {
  color: #021b64;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;

  @media screen and (max-width: 700px) {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.margin-title {
  margin-top: 8px;
}

.content-text {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;

  line-height: 30px;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}
.bold {
  font-weight: bold;
}
.el-button {
  font-size: 18px;
  font-weight: 400;
}
.short-space {
  display: inline-block;
  width: 32px;
  height: 10px;
}
.long-space {
  display: inline-block;
  width: 52px;
  height: 10px;
}
.long-space-1 {
  display: inline-block;
  width: 47px;
  height: 10px;
}
.long-space-2 {
  display: inline-block;
  width: 36px;
  height: 10px;
}
.shore-space-between {
  display: inline-block;
  height: 10px;
  width: 10px;
}
.table-long-side {
  width: 200px;
}
.table-empit {
  width: 245px;
  height: 10px;
}
table {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
  margin-top: 20px;
}
td,
th {
  padding: 10px 5px;
}
.table {
  color: #021b64;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
}
.end-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
}
.end-text {
  width: 400px;
  padding: 5px 0;
}
</style>
