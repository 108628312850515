<template>
    <div class="more">
        <div id="pc" :class="'part' + i.id" v-for="i in list" :key="i.id" @mouseover="over(i.id, $event)"
            @mouseleave="leave(i.id, $event)">{{ i.value }}
        </div>
        <div id="app" :class="'part' + i.id" v-for="i in list" :key="i.id">
            {{ i.value }}
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
const list = ref([
    { id: 1, value: '客户信息管理' },
    { id: 2, value: '对话评价' },
    { id: 3, value: '考勤统计' },
    { id: 4, value: '消息撤回' },
    { id: 5, value: '考勤统计' },
    { id: 6, value: '输入预知' },
    { id: 7, value: '强制对话' },
    { id: 8, value: '智能分配对话' },
    { id: 9, value: '同事协同对话' },
    { id: 10, value: '智能分配对话' },
    { id: 11, value: '输入预知' },
    { id: 12, value: '强制对话' },
    { id: 13, value: '排队功能' },
    { id: 14, value: '智能分配对话' },
    { id: 15, value: '访客黑名单' },
    { id: 16, value: '转接对话' },
    { id: 17, value: '自动消息' },
    { id: 18, value: '标签功能' },
    { id: 19, value: '会员对接' },
    { id: 20, value: '询前表单' },
    { id: 21, value: '输入预知' },
    { id: 22, value: '排队功能' },
    { id: 23, value: '标签功能' },
    { id: 24, value: '强制对话' },
    { id: 25, value: '标签功能' },
    { id: 26, value: '同事协同对话' },
    { id: 27, value: '标签功能' },
    { id: 28, value: '消息撤回' },
    { id: 29, value: '询前表单' },
    { id: 30, value: '智能分配对话' },
])
// 鼠标悬停
const over = (val, event) => {
    if (val === 1 || val === 2 || val === 3 || val === 4 || val === 5 || val === 8 || val === 13 || val === 14 || val === 15 || val === 18 || val === 19 || val === 22 || val === 23 || val === 25 || val === 26 || val === 27 || val === 30) {
        event.target.style.fontSize = 24 + 'px'
    } else if (val === 6 || val === 7 || val === 11 || val === 12 || val === 16 || val === 17 || val === 20 || val === 21 || val === 24 || val === 28 || val === 29) {
        event.target.style.fontSize = 20 + 'px'
    } else if (val === 9) {
        event.target.style.fontSize = 41 + 'px'
    } else {
        event.target.style.fontSize = 35 + 'px'
    }
}
// 鼠标离开
const leave = (val, event) => {
    if (val === 1 || val === 2 || val === 3 || val === 4 || val === 5 || val === 8 || val === 13 || val === 14 || val === 15 || val === 18 || val === 19 || val === 22 || val === 23 || val === 25 || val === 26 || val === 27 || val === 30) {
        event.target.style.fontSize = 20 + 'px'
    } else if (val === 6 || val === 7 || val === 11 || val === 12 || val === 16 || val === 17 || val === 20 || val === 21 || val === 24 || val === 28 || val === 29) {
        event.target.style.fontSize = 16 + 'px'
    } else if (val === 9) {
        event.target.style.fontSize = 37 + 'px'
    } else {
        event.target.style.fontSize = 31 + 'px'
    }
}
</script>
<style scoped lang="less">
#pc {
    @media screen and (max-width:700px) {
        display: none;
    }
}

#app {
    display: none;

    @media screen and (max-width:700px) {
        display: inline-block;
    }
}

.more {
    width: 1300px;
    height: 260px;
    position: relative;
    margin-top: 30px;
    font-weight: 500;
    cursor: default;

    @media screen and (max-width:700px) {
        width: 400px;
    }
}

.part1 {
    position: absolute;
    top: 0;
    left: 0;
    color: #112D82;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
    }
}

.part2 {
    position: absolute;
    top: 15px;
    left: 250px;
    color: #112D82;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 50px;
    }
}

.part3 {
    position: absolute;
    top: 0;
    left: 503px;
    color: #112D82;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 200px;
    }
}

.part4 {
    position: absolute;
    top: 45px;
    left: 120px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 24px;
    }
}

.part5 {
    position: absolute;
    top: 45px;
    left: 435px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 157px;
    }
}

.part6 {
    position: absolute;
    top: 65px;
    left: 245px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 49px;
    }
}

.part7 {
    position: absolute;
    top: 70px;
    left: 350px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 200px;
    }
}

.part8 {
    position: absolute;
    top: 85px;
    left: 10px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 50px;
    }
}

.part9 {
    position: absolute;
    top: 110px;
    left: 165px;
    color: #112D82;
    opacity: 60%;
    font-weight: 600;
    font-size: 37px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 27px;
        left: 33px;
    }
}

.part10 {
    position: absolute;
    top: 85px;
    left: 625px;
    color: #112D82;
    opacity: 60%;
    font-size: 31px;
    font-weight: 600;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 21px;
        left: 153px;
    }
}

.part11 {
    position: absolute;
    top: 35px;
    left: 620px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 252px;
    }
}

.part12 {
    position: absolute;
    top: 35px;
    left: 760px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 290px;
        top: 45px;
    }
}

.part13 {
    position: absolute;
    top: 150px;
    left: 75px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 3px;
    }
}

.part14 {
    position: absolute;
    top: 15px;
    left: 890px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 136px;
        top: 20px;
    }
}

.part15 {
    position: absolute;
    top: 120px;
    left: 455px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 219px;
    }
}

.part16 {
    position: absolute;
    top: 165px;
    left: 490px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 94px;
    }
}

.part17 {
    position: absolute;
    top: 200px;
    left: 492px;
    color: #112D82;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 46px;
    }
}

.part18 {
    position: absolute;
    top: 190px;
    left: 48px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 137px;
    }
}

.part19 {
    position: absolute;
    top: 170px;
    left: 190px;
    color: #112D82;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 166px;
    }
}

.part20 {
    position: absolute;
    top: 200px;
    left: 353px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 198px;
    }
}

.part21 {
    position: absolute;
    top: 80px;
    left: 870px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 302px;
    }
}

.part22 {
    position: absolute;
    top: 130px;
    left: 899px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 300px;
    }
}

.part23 {
    position: absolute;
    top: 150px;
    left: 765px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 281px;
    }
}

.part24 {
    position: absolute;
    top: 170px;
    left: 650px;
    color: #020C30;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 258px;
    }
}

.part25 {
    position: absolute;
    top: 185px;
    left: 945px;
    color: #112D82;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 290px;
    }
}

.part26 {
    position: absolute;
    top: 10px;
    left: 1045px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 337px;
    }
}

.part27 {
    position: absolute;
    top: 90px;
    left: 1025px;
    color: #020C30;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 333px;
        top: 100px;
    }
}

.part28 {
    position: absolute;
    top: 85px;
    left: 1135px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 355px;
    }
}

.part29 {
    position: absolute;
    top: 135px;
    left: 1080px;
    color: #112D82;
    opacity: 60%;
    font-size: 16px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 6px;
        left: 344px;
    }
}

.part30 {
    position: absolute;
    top: 190px;
    left: 1060px;
    color: #6DD6FF;
    opacity: 60%;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 20px;

    @media screen and (max-width:700px) {
        padding: 0px;
        font-size: 10px;
        left: 340px;
    }
}
</style>
