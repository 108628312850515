<template>
  <div class="home" id="swipercom">
    <img
      class="bgimg"
      src="@/assets/home/home1.png"
      v-if="parentValue != '2'"
    />
    <div class="swiper-container" id="swiperIndex">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="card-img">
            <img src="@/assets/home/homecard3.png" alt="在线客服" />
          </div>
          <div class="card-title">在线客服</div>
          <div class="card-little-title">
            多端口集成统一对话窗口，快速人机协作，提高效率
          </div>
          <div class="card-content">
            <ul>
              <li>PC网站、移动网站、App</li>
              <li>微信公众号、微信小程序和微博客服对话集成</li>
              <li>统一对话窗口，统一客服管理界面</li>
            </ul>
          </div>
          <div class="button">
            <button @click="learnMore('1')">了解更多</button>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="card-img workorder">
            <img src="@/assets/home/homecard1.png" alt="工单系统" />
          </div>
          <div class="card-title">工单系统</div>
          <div class="card-little-title">
            支持不同状态标记，支持全渠道工单建立
          </div>
          <div class="card-content">
            <ul>
              <li>一键快速建立工单</li>
              <li>自定义工单字段</li>
              <li>快速回复处理</li>
              <li>延时性解决方案</li>
            </ul>
          </div>
          <div class="button">
            <button @click="learnMore('2')">了解更多</button>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="card-img robot">
            <img src="@/assets/home/homecard2.png" alt="智能机器人" />
          </div>
          <div class="card-title">智能机器人</div>
          <div class="card-little-title">
            高效解决重复问题、知识库自定义管理、自主监督式学习
          </div>
          <div class="card-content">
            <ul>
              <li>7x24小时全天在线</li>
              <li>人机协作模式</li>
              <li>知识库自定义及管理</li>
              <li>自主+监督式学习</li>
            </ul>
          </div>
          <div class="button">
            <button @click="learnMore('3')">了解更多</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
  name: "Swiper",
  data() {
    return {
      screenWidth: document.body.clientWidth,
      parentValue: "1",
    };
  },
  props: {
    parentValue: { type: String, default: "" },
  },
  mounted() {
    var mySwiper = new Swiper("#swiperIndex", {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 45,
      centeredSlides: true,
      autoplay: true,
      autoplay: {
        delay: 2000,
      },
    });
    mySwiper.el.onmouseover = function () {
      mySwiper.autoplay.stop();
    };
    mySwiper.el.onmouseout = function () {
      mySwiper.autoplay.start();
    };
  },
  methods: {
    learnMore(val) {
      if (val === "1") {
        this.$router.push("/customer");
      } else if (val === "2") {
        this.$router.push("/workorder");
      } else {
        this.$router.push("/robot");
      }
    },
  },
};
</script>

<style scoped lang="less">
.home .swiper-slide {
  margin-top: 50px;
  background: #fff;
  height: 452px;
  border-radius: 8px;
  position: relative;
}

.home .swiper-slide-active {
  margin-top: 50px;
  border-radius: 8px;
  background: #fff;
  height: 482px;
}

.home #swipercom {
  width: 100%;
  height: 550px;
  margin: 180px 0 0px 0;
  position: relative;

  @media screen and (max-width: 700px) {
    margin: 50px 0 50px 0;
  }
}

.bgimg {
  width: 1200px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -600px;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.card-title {
  font-size: 38px;
  color: #020d31;
  padding: 70px 0 0 100px;

  @media screen and (max-width: 700px) {
    font-size: 25px;
    padding: 40px 10px 0 20px;
  }
}

.card-little-title {
  font-size: 20px;
  color: #020d31;
  padding: 20px 0 0 100px;

  @media screen and (max-width: 700px) {
    font-size: 12px;
    padding: 20px 10px 0 20px;
  }
}

.card-content {
  font-size: 18px;
  color: #020d31;
  opacity: 0.6;
  padding: 20px 0 0 100px;

  @media screen and (max-width: 700px) {
    padding: 30px 10px 0 20px;
    font-size: 10px;
  }
}

.card-content ul {
  padding-left: 25px;

  @media screen and (max-width: 700px) {
    padding-left: 20px;
  }
}

.card-content ul li {
  padding-bottom: 10px;

  @media screen and (max-width: 700px) {
    padding-bottom: 5px;
  }
}

.card-content p {
  padding-bottom: 15px;

  @media screen and (max-width: 700px) {
    padding-bottom: 10px;
  }
}

.button {
  padding: 25px 0 0 100px;

  @media screen and (max-width: 700px) {
    padding: 25px 0 0 20px;
  }
}

.button button {
  outline: none;
  width: 140px;
  height: 46px;
  color: #fff;
  background-color: #78d3ff;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 100px;
    height: 36px;
  }
}

.card-img {
  position: absolute;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 700px) {
    bottom: -20px;
    right: -20px;
  }
}

.card-img img {
  width: 283px;
  height: 312px;

  @media screen and (max-width: 700px) {
    width: 193px;
    height: 182px;
  }
}

.robot img {
  width: 278px;
  height: 254px;

  @media screen and (max-width: 700px) {
    width: 178px;
    height: 154px;
  }
}

.workorder img {
  width: 323px;
  height: 306px;

  @media screen and (max-width: 700px) {
    width: 223px;
    height: 206px;
  }
}
</style>
