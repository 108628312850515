<template>
  <div class="Applet">
    <div class="bg">
      <img src="@/assets/thirdPartyPlatform/Tiktok1.png" />
    </div>
    <!-- <div class="swarpper">
      <div class="swarpper-main">
        <div id="swipercom">
          <div class="swiper-container" id="swiperIndex">
            <div class="swiper-wrapper">
              <div class="swiper-slide">1</div>
              <div class="swiper-slide">2</div>
              <div class="swiper-slide">3</div>
              <div class="swiper-slide">4</div>
              <div class="swiper-slide">5</div>
              <div class="swiper-slide">6</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 轮播信息 -->
    <div class="home-center">
      <home-vue :parentValue="2"></home-vue>
    </div>
    <!-- 小美客服优势多多 -->
    <div class="part1">
      <div class="img">
        <div class="title-box">
          <div class="arrow arrow-start"></div>
          <h1>小美抖音客服优势多多</h1>
          <div class="arrow arrow-end"></div>
        </div>
      </div>
      <div class="text">
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">
            账号高效管理<span class="nbsp1"></span>
          </div>
          <div class="text-text">
            集成多个抖音号为一个界面，有一一对应的好友和会话，新消息标记置顶，无需来回切换屏幕。实现无损耗、无遗漏的聊天，有效提高咨询效率。
          </div>
        </div>
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">PC端、手机端同时接待</div>
          <div class="text-text">
            抖主可以招客服人员进行专门的PC电脑端线上接待，同时自己也可以用手机对主力消费群体进行重点维护。
          </div>
        </div>
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">
            粉丝分组管理<span class="nbsp2"></span>
          </div>
          <div class="text-text">
            抖音接入客服系统能够在电脑上将粉丝像QQ分组一样根据不同类型进行分组管理。
          </div>
        </div>
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">
            批量私信群发<span class="nbsp3"></span>
          </div>
          <div class="text-text">
            接入客服系统以后可以实现私信群发，当发布了作品想要快速增加展现量的时候，就可以通过粉丝私信群发的方式通过私信告知粉丝观看，增加播放量。
          </div>
        </div>
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">
            粉丝评论+私信回复<span class="nbsp4"></span>
          </div>
          <div class="text-text">
            抖音接入在线客服系统以后，最主要的好处就是能够把所有粉丝发来的私信通过客服系统进行统一接待，并且除了私信外，还可以直接对粉丝评论进行回复。
          </div>
        </div>
        <div class="text-main">
          <div class="text-first">
            <img src="@/assets/thirdPartyPlatform/icon-dian.png" />
          </div>
          <div class="text-text shot">
            机器人客服接待<span class="nbsp5"></span>
          </div>
          <div class="text-text">
            可以利用客服系统的智能化客服机器人来进行7x24小时全时段接待。
          </div>
        </div>
      </div>
      <div class="part1-bg">
        <img src="@/assets/thirdPartyPlatform/wechat3.png" />
      </div>
    </div>
    <div class="part2">
      <div class="title-box">
        <div class="arrow arrow-start"></div>
        <h1>抖音接入客服系统步骤</h1>
        <div class="arrow arrow-end"></div>
      </div>
      <div class="part2-img">
        <div class="part2-text">
          <span class="span1">进入小美</span>
          <span class="span2">【设置】 - 【微信公众号】 - 【扫码接入】</span>
          <span class="span3">使用公众号管理员微信账号进行扫码授权</span>
          <span class="span4">完成</span>
        </div>
        <img class="img1" src="@/assets/thirdPartyPlatform/Applet10.png" />
        <img class="img2" src="@/assets/thirdPartyPlatform/Applet7.png" />
        <img class="img3" src="@/assets/thirdPartyPlatform/Applet8.png" />
        <img class="img4" src="@/assets/thirdPartyPlatform/Applet9.png" />
      </div>
    </div>
    <div class="part3">
      <div>
        <img src="@/assets/thirdPartyPlatform/Tiktok2.png" alt="小美智能云服" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeVue from "../home/home.vue";
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
  name: "Swiper",
  data() {
    return {};
  },
  components: {
    HomeVue,
  },
  mounted() {
    var mySwiper = new Swiper("#swiperIndex", {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 45,
      centeredSlides: true,
      autoplay: true,
      autoplay: {
        delay: 2000,
      },
    });
  },
  metaInfo: {
    title: "抖音 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
};
</script>
<style scoped lang="less">
.nbsp1,
.nbsp2,
.nbsp3 {
  display: inline-block;
  width: 103px;
  @media screen and (max-width: 700px) {
    width: 43px;
  }
}
.nbsp4 {
  display: inline-block;
  width: 39px;
  @media screen and (max-width: 700px) {
    width: 17px;
  }
}
.nbsp5 {
  display: inline-block;
  width: 82px;
  @media screen and (max-width: 700px) {
    width: 33px;
  }
}
.Applet {
  background-color: #0d2473;
}

.swiper-wrapper {
  margin-top: 60px;

  @media screen and (max-width: 700px) {
    margin-top: 20px;
  }
}

.swiper-slide {
  background: #fff;
  //   height: 461px;
  height: 386px;
  @media screen and (max-width: 700px) {
    height: 160px;
  }
}

.swiper-slide-active {
  background: #fff;
  //   height: 517px;
  height: 438px;
  margin-top: -25px;

  @media screen and (max-width: 700px) {
    height: 180px;
    margin-top: -10px;
  }
}

.swarpper-main {
  width: 1200px;
  height: 517px;

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 210px;
  }
}

.bg img {
  height: 754px;
  width: 100%;

  @media screen and (max-width: 700px) {
    height: 180px;
  }
}

.swarpper {
  width: 100%;
  height: 517px;
  padding: 80px 0 120px 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    height: 210px;
    padding: 80px 0 70px 0;
  }
}

.part1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   height: 850px;
  height: 1070px;
  position: relative;
  @media screen and (max-width: 700px) {
    // height: 550px;
    height: 400px;
  }
}

.part2 {
  width: 100%;
  height: 563px;
  background-color: #020f3b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 263px;
  }
}

.part3 {
  background-color: #0d2473;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 80px;
  }
}
.part3 img {
  @media screen and (max-width: 700px) {
    height: 15px;
    width: 350px;
  }
}

.title {
  width: 528px;
  height: 39px;
}

.text {
  width: 1100px;
  height: 705px;
  padding: 30px 50px;
  margin-top: 110px;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  @media screen and (max-width: 700px) {
    width: 360px;
    height: 345px;
    padding: 30px 20px;
    margin-top: 50px;
  }
}

.text-main {
  display: flex;
  margin-bottom: 50px;
  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
}

.text-first {
  margin-top: -8px;
  @media screen and (max-width: 700px) {
    margin-top: -8px;
  }
}
.text-first img {
  width: 50px;
  height: 50px;
  @media screen and (max-width: 700px) {
    width: 30px;
    height: 30px;
  }
}

.text-text {
  font-size: 24px;
  color: #fff;
  opacity: 0.9;
  display: inline-block;
  @media screen and (max-width: 700px) {
    font-size: 10px;
  }
}

.shot {
  color: #6dd6ff;
  margin: 0 30px 0 10px;
  white-space: nowrap;
  @media screen and (max-width: 700px) {
    margin: 0 15px 0 10px;
  }
}
.part1-bg {
  width: 1342px;
  height: 916px;
  position: absolute;
  bottom: -290px;
  left: 50%;
  margin-left: -671px;
}

.part1-bg img {
  width: 1342px;
  height: 335px;
  @media screen and (max-width: 700px) {
    width: 440px;
    height: 135px;
  }
}

.arrow {
  width: 0;
  height: 0;
  font-size: 0;
  border-top: solid 7px;
  border-bottom: solid 7px;
  border-left: solid 14px;
  border-right: solid 14px;
  @media screen and (max-width: 700px) {
    border-top: solid 5px;
    border-bottom: solid 5px;
    border-left: solid 10px;
    border-right: solid 10px;
  }
}

.arrow-start {
  border-color: transparent transparent transparent #6dd6ff;
  margin-right: 20px;
}

.arrow-end {
  border-color: transparent #6dd6ff transparent transparent;
  margin-left: 20px;
}

.title-box {
  display: flex;
  align-items: center;
}

.title-box h1 {
  color: #fff;
  font-size: 38px;
  letter-spacing: 5px;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
}
.part2-img {
  margin-top: 80px;
  position: relative;
  @media screen and (max-width: 700px) {
    margin-top: 50px;
  }
}

.part2-text {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0;
  color: #fff;
  font-size: 20px;
  @media screen and (max-width: 700px) {
    font-size: 8px;
    margin-top: -8px;
  }
}

.img1,
.img4 {
  @media screen and (max-width: 700px) {
    width: 70px;
    height: 60px;
  }
}
.img1,
.img2,
.img3 {
  @media screen and (max-width: 700px) {
    margin-right: -10px;
  }
}
.img2,
.img3 {
  @media screen and (max-width: 700px) {
    width: 160px;
    height: 60px;
  }
}
.span1 {
  margin: 0 120px 0 40px;

  @media screen and (max-width: 700px) {
    margin: 0px 24px 0 15px;
  }
}

.span3 {
  margin: 0 130px 0 105px;

  @media screen and (max-width: 700px) {
    margin: 0 25px 0 2px;
  }
}
</style>
