<template>
  <div>
    <div class="top">
      <img src="@/assets/introduce/i1.png" />
      <div class="top-box">
        <h1 class="top-box-title">资质荣誉</h1>
        <div id="swipercom">
          <div class="swiper-container" id="swiperIndex">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP01mainSystem.jpg"
                  alt="计算机软件授权证书1"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美智能客服系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP05workOrder.jpg"
                  alt="计算机软件授权证书2"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美客服工单系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP07wordAnalysis.jpg"
                  alt="计算机软件授权证书3"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美智能客服语音敏感词分析系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP08workDistribution.jpg"
                  alt="计算机软件授权证书4"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  客服工作台企业高级自定义分配系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP09ai.jpg"
                  alt="计算机软件授权证书5"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美智能机器人系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP10knowledgeCluld.jpg"
                  alt="计算机软件授权证书6"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美智能客服知识库分类系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP12informationPush.jpg"
                  alt="计算机软件授权证书7"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美通用名片消息推送系统
                </p> -->
              </div>
              <div class="swiper-slide">
                <img
                  src="../../assets/honor/IP15talkCharts.jpg"
                  alt="计算机软件授权证书8"
                />
                <!-- <p
                  style="
                    text-align: center;
                    font-family: PingFangSC-Regular;
                    font-size: 22px;
                    color: #626b83;
                    font-weight: 400;
                  "
                >
                  小美客服对话统计系统
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
  name: "Swiper",
  metaInfo: {
    title: "资质荣誉 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  data() {
    return {};
  },
  mounted() {
    var mySwiper = new Swiper("#swiperIndex", {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 45,
      centeredSlides: true,
      autoplay: true,
      autoplay: {
        delay: 3000,
      },
    });
  },
};
</script>
<style scoped lang="less">
.swiper-wrapper {
  margin-top: 50px;
}
.swiper-slide {
  background: #fff;
  height: 440px;
  @media screen and (max-width: 700px) {
    height: 130px;
    //    margin-left: -10px;
    //    width: 130px !important;
  }
}

.swiper-slide-active {
  background: #fff;
  height: 490px;
  // width: 280px !important;
  margin-top: -25px;
  // margin-left: -5px;
  @media screen and (max-width: 700px) {
    height: 150px;
    margin-top: -10px;
  }
}

.top {
  height: 1050px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    height: 950px;
  }
}

.top img {
  width: 100%;
  height: 432px;
  @media screen and (max-width: 700px) {
    height: 150px;
  }
}

.top-box {
  width: 1200px;
  height: 724px;
  background-color: #fff;
  position: absolute;
  top: 216px;
  left: 50%;
  margin-left: -600px;
  @media screen and (max-width: 700px) {
    top: 150px;
    width: 100%;
    left: 0;
    margin-left: 0px;
  }
}

.top-box-title {
  color: #021b64;
  font-size: 34px;
  text-align: center;
  margin-top: 100px;
  font-weight: 500;
}
</style>
