import { createStore } from "vuex";
// import customer from "./modules/customer";

export default createStore({
  state: {
    token: "token",
    activeIndex:''
  },
  getters: {},
  mutations: {
    SET_ACTIVE_INDEX(state,data){
      state = data
    }
  },
  actions: {},
  modules: {
    // customer
  },
});
