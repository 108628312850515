import { createApp } from "vue";
import MetaInfo from 'vue-meta-info'
import store from "./store";
import ElementPlus from "element-plus";
import router from "./router";
// 引入阿里云字体图标css
import './assets/icons/iconfont/iconfont.css' 
import "element-plus/dist/index.css";
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'



// 引入全局样式
// import "./style/developer.css";   
import "./style/index.css";   
import App from "./App.vue";
// 拖拽
import Directives from "@/utils/directives";
router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

createApp(App)
  .use(store)
  .use(ElementPlus)
  .use(router)
  .use(Directives)
  .use(MetaInfo)
  .use(hljsVuePlugin)
  .mount("#app");
