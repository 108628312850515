<template>
  <div class="work-part">
    <div class="work">
      <img src="@/assets/work/work1.png" alt="建立工单" />
      <p class="work-title">一键快速建立工单</p>
      <div class="work-content">多种对话机制最大程度的促进成交转化率</div>
    </div>
    <div class="work">
      <img src="@/assets/work/work2.png" alt="自定义工单字段" />
      <p class="work-title">自定义工单字段</p>
      <div class="work-content">
        通过访客的访问页面及停留时间确定访客的意向服务和产品
      </div>
    </div>
    <div class="work">
      <img src="@/assets/work/work3.png" alt="延时性解决方案" />
      <p class="work-title">延时性解决方案</p>
      <div class="work-content">
        上厕所错过客户消息？通过绑定微信公众号蹲坑也能及时回复消息
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
onMounted(() => {});
</script>
<style scoped lang="less">
.work {
  background-color: #fff;
  width: 306px;
  height: 260px;
  padding: 40px;
  margin: 0 20px;
  @media screen and (max-width: 700px) {
    //    padding: 30px;
    margin: 15px 20px;
  }
}

.work img {
  width: 60px;
  height: 60px;
  @media screen and (max-width: 700px) {
    margin: 15px 0;
  }
}

.work-part {
  display: flex;
  justify-content: center;
  margin: 60px 0;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.work-title {
  color: #021b64ff;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding: 20px 0 30px 0;
  @media screen and (max-width: 700px) {
  }
}

.work-content {
  width: 184px;
  text-align: center;
  color: #021b64ff;
  opacity: 70%;
  font-size: 18px;
  margin: 0 auto;
  @media screen and (max-width: 700px) {
  }
}
</style>
