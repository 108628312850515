<template>
  <div>
    <!-- 背景 -->
    <div class="workImg">
      <!-- <img src="@/assets/robot/banner_02.png" /> -->
      <!-- 背景文字 -->
      <div class="customer-title">
        <h1 class="customer-big-title">智能机器人</h1>
        <h2 class="customer-little-title">根据您的习惯和需要定制客服软件</h2>
        <h2 class="customer-little-title br">
          我们用最新的技术来确保您和客户之间保持顺畅的沟通体验
        </h2>
      </div>
    </div>
    <!--  -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">核心功能</div>
        <div class="center-two-little-title">
          根据您的习惯和需要定制客服软件<br />我们用最新的技术来确保您和客户之间保持顺畅的沟通体验
        </div>
      </div>
      <div class="work-card">
        <robot-vue></robot-vue>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="center-two bottom">
      <div class="center-two-top">
        <div class="center-two-title">产品优势</div>
      </div>
      <div>
        <advantage-vue></advantage-vue>
      </div>
    </div>
    <!-- 技术方案 -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">技术方案</div>
        <div class="center-two-little-title">
          根据您的习惯和需要定制客服软件<br />我们用最新的技术来确保您和客户之间保持顺畅的沟通体验
        </div>
      </div>
      <div class="img">
        <img src="@/assets/robot/r4.png" />
      </div>
    </div>
    <!-- 多企业 -->
    <div class="other">
      <multienter-prise-vue />
    </div>

    <!-- 试用 -->
    <div>
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import AdvantageVue from "./Advantage";
import RobotVue from "./robot.vue";
import OnTrialVue from "@/components/OnTrial.vue";
import MultienterPriseVue from "@/components/MultienterPrise.vue";
import Advantage from "./Advantage.vue";
export default {
  metaInfo: {
    title: "智能机器人 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    Advantage,
    MultienterPriseVue,
    OnTrialVue,
    RobotVue,
    AdvantageVue,
  },
};
</script>
<style scoped lang="less">
.other {
  margin-top: -70px;

  @media screen and (max-width: 700px) {
    margin-top: 0px;
  }
}

.img img {
  width: 660px;
  height: 660px;
  margin: 50px 0;

  @media screen and (max-width: 700px) {
    width: 460px;
    height: 460px;
    margin: 25px 0;
  }
}

.bottom {
  margin-bottom: 80px;
}

.customer-title {
  position: absolute;
  top: 35%;
  left: 11%;

  @media screen and (max-width: 700px) {
    top: 35%;
    left: 10%;
  }
}

.workImg {
  position: relative;
  width: 100%;
  height: 644px;
  background-image: url("@/assets/robot/banner_02.png");
  background-size: 105% 100%;

  @media screen and (max-width: 700px) {
    position: relative;
    width: 100%;
    height: 200px;
    background-image: url("@/assets/robot/banner_02.png");
    background-size: 105% 100%;
    margin-bottom: 20px;
  }
}

.workImg img {
  width: 100%;
  height: 644px;

  @media screen and (max-width: 700px) {
    height: 184px;
  }
}

.customer-big-title {
  color: #fff;
  font-size: 52px;
  margin-bottom: 16px;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.customer-little-title {
  color: #fff;
  opacity: 80%;
  font-size: 17px;
  font-weight: 400;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.br {
  margin-top: 5px;
}

.center-two {
  /* padding-top: 80px; */
  text-align: center;
  /* position: relative; */
  background-color: #f4f7ff;
}

.center-two-title {
  color: #020c30;
  font-size: 38px;
  padding-bottom: 15px;

  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.center-two-little-title {
  color: #020c30;
  font-size: 20px;
  opacity: 60%;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}
</style>
