<template>
  <div>
    <div class="box box1" style="height: 120px">
      <div class="title">全渠道分钟级接入</div>
      <div class="content">
        可通过微信公众号、小程序、Web 端、Android、IOS
        等渠道接入。只需三分钟就可以接入微信公众号，让公 众号有智能问答功能。
      </div>
    </div>
    <div class="box box2" style="height: 150px">
      <div class="title">自主学习</div>
      <div class="content">
        在用户与智能客服机器人对话的过程中，机器人会根据用户的反馈自主学习，调整对话策略和对话结果，随着反
        馈的增
        多，机器人理解用户意图会越来越精准，这一过程是完全自主的，无需用户干预。
      </div>
    </div>
    <div class="box box3">
      <div class="title">开放平台</div>
      <div class="content shot">
        智能客服产品是 SAAS
        开放平台服务，任何企业和个人都可以注册使用，进行简单配置后，拥有属于自己的客服
        机器人，并且可以通过各种不同渠道快速接入。除了特殊需求外，所有功能用户都可以自主配置，即时可用。
      </div>
      <div class="img">
        <img src="@/assets/robot/r5.png" alt="机器人" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
onMounted(() => {});
</script>
<style scoped lang="less">
.box {
  background-color: hsla(225, 57%, 44%, 0.06);
  // width: 1234px;
  width: 1080px;
  height: 190px;
  margin: 0 auto;
  padding: 25px;
  margin-bottom: 20px;

  @media screen and (max-width: 700px) {
    width: 310px;
    padding: 35px;
    height: 180px;
  }
}

.box3 {
  height: 205px;
  position: relative;
  @media screen and (max-width: 700px) {
    height: 230px;
  }
}

.title {
  color: #021b64;
  font-size: 22px;
  text-align: start;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 30px;
  margin-left: 30px;

  @media screen and (max-width: 700px) {
    margin-left: 0px;
    margin-bottom: 15px;
    margin-top: 0px;
  }
}

.content {
  color: #021b64;
  opacity: 0.7;
  font-size: 18px;
  text-align: start;
  margin-left: 30px;

  @media screen and (max-width: 700px) {
    margin: 0px;
  }
}

.shot {
  //   width: 500px;
  width: 70%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.img {
  position: absolute;
  top: -20px;
  right: -10px;
  @media screen and (max-width: 700px) {
    position: absolute;
    top: 200px;
    right: -15px;
  }
}

.img img {
  width: 319px;
  height: 270px;
  @media screen and (max-width: 700px) {
    width: 150px;
    height: 120px;
  }
}
</style>
