<template>
  <div class="question">
    <!-- <div class="top">
      <div class="top-box">
        <button class="top-button">js控件添加访客自定义信息</button>
        <button class="top-button">链接添加访客自定义信息</button>
        <button class="top-button">自定义域名添加访客自定义信</button>
      </div>
      <div class="state"></div>
    </div> -->
    <div class="end">
      <div class="end-box-dev">
        <div class="meun">
          <h3 style="padding-left: 25px">开发文档</h3>
          <!-- <el-menu
            default-active="0"
            background-color="#fff"
            text-color="#333"
            active-text-color="#fff"
          >
            <el-menu-item
              @click="menuItemClick(index)"
              v-for="(list, index) in leftList"
              :key="index"
              :index="index.toString()"
            >
              <span slot="title">{{ list.label }}</span>
            </el-menu-item>
          </el-menu> -->
          <el-tree
            :data="selectData"
            :props="defaultProps"
            :default-expand-all="true"
            :default-checked-keys="[4]"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="content">
          <div>
            <!-- <div class="content-title">浮动窗口接入JS</div> -->
            <div class="content-text">
              <!-- <div class="content-title">浮动窗口接入JS</div> -->
              <ul class="text_main">
                <li v-if="checkout == 4">
                  <span class="codeDemoTitle">设置用户信息</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来设置用户信息，以便区分不同用户</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('appId', '企业ID');</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      class="nohighlight"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>设置用户信息</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU'); 

      <!-- 设置企业id为xxx -->
      _MKEFU('appId', 'xxx');

    </script>
  </html>"
                    />
                  </div>
                </li>
                <li v-if="checkout == 5">
                  <span class="codeDemoTitle">设置用户ID</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来设置用户ID，使每个用户有自己对应的键值</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('MKUserId',"用户ID");</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>设置用户ID</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置用户ID为xxx-->
      _MKEFU('MKUserId', 'xxx');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 6">
                  <span class="codeDemoTitle">设置用户名称</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来设置用户名称</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain"
                      >_MKEFU('MKUserName',"用户名称");</span
                    >
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>设置用户名称</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置用户名称为xxx -->
      _MKEFU('MKUserName', 'xxx');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 7">
                  <span class="codeDemoTitle">添加访客自定义信息</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来自定义功能，自定义信息的格式为:key1:value1;key2:value2:</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain"
                      >_MKEFU('MKUserInfo',"电话号码:13866666666;订单号码:2019121212666;");</span
                    >
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>自定义信息</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 添加访客自定义信息为：电话号码:13866666666;订单号码:2019121212666; -->
      _MKEFU('MKUserInfo', '电话号码:13866666666;订单号码:2019121212666;');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 8">
                  <span class="codeDemoTitle">指定客服</span>
                  <span class="codeDemoIllustrate"
                    >通常客服工作台就可以设置各种条件指定客服来满足大部分客服分配需求，我们还支持通过开放接口在对话链接
                    URL 里指定客服接待。</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('MKUId',"客服Id");</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>指定客服</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置指定客服id为xxx -->
      _MKEFU('MKUId', 'xxx');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 9">
                  <span class="codeDemoTitle">指定分组</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来分配分组信息</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('MKGId',"分组Id");</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>指定分组</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置指定分组id为xxx -->
      _MKEFU('MKGId', 'xxx');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 10">
                  <span class="codeDemoTitle">指定语言</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过传递不同的语言参数来指定语言服务</span
                  >
                  <span class="codeDemoIllustrate">* 语言说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >zhCN</span
                      >
                      <span style="padding-left: 10px"> 为简体中文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >enUS</span
                      >
                      <span style="padding-left: 10px"> 为英文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >vi</span
                      >
                      <span style="padding-left: 10px"> 为越南语</span>
                    </li>
                  </ul>
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('MKLang', "zhCN")</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>指定语言</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置指定语言为简体中文 -->
      _MKEFU('MKLang', 'zhCN');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 11">
                  <span class="codeDemoTitle">指定访客Id</span>
                  <span class="codeDemoIllustrat"
                    >您可以通过该函数来指定访客访问,访客的id不能超过32位</span
                  >
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('MKVId',"访客Id");</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>指定访客</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置指定访客id为xxx -->
      _MKEFU('MKVId', 'xxx');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 12">
                  <span class="codeDemoTitle">隐藏按钮</span>
                  <span class="codeDemoIllustrat"
                    >您可通过该函数传递不同参数信息来使控件隐藏或展示浮动按钮，常规情况下，需要紧跟在监控代码后面</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >'float', '0'</span
                      >
                      <span style="padding-left: 10px"> 为按钮隐藏</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >'float', '1'
                      </span>
                      <span style="padding-left: 10px"> 为按钮展示</span>
                    </li>
                  </ul>
                  <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">_MKEFU('float',"0");</span>
                  </div>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
  <!DOCTYPE html>
  <html lang='en'>
    <head>
      <meta charset='utf-8'>
      <meta content='always' name='referrer'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta content='yes' name='apple-mobile-web-app-capable'>
      <meta name='viewport' content='width=device-width,height=device-height,inital-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no;''>
      <title>控件隐藏浮动按钮</title>
    </head>
    <body>
      <div class='page-main'>
    </body>

    <script type='text/javascript'>
      (function(x, i, ao, m, ei, j, s) {
        x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
        j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.id='MKEFU_WEBPLUGIN';
        j.src='https://xxx.com/static/js/mkefu.min.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MKEFU');

      <!-- 设置隐藏浮动按钮 -->
      _MKEFU('float', '0');

    </script>
  </html>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 13">
                  <span class="codeDemoTitle">自定义按钮</span>
                  <span class="codeDemoIllustrat"
                    >您可通过以下链接接入以便自定义您需要的功能</span
                  >
                  <div class="codetitle">
                    <span
                      style="font-size: 20px; color: orange; padding-top: 10px"
                      >开启对话:
                    </span>
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">{{ chatbegin }}</span>
                  </div>
                  <div class="codetitle">
                    <span
                      style="font-size: 20px; color: orange; padding-top: 10px"
                      >指定客服:
                    </span>
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">{{ appointkefu }}</span>
                  </div>

                  <div class="codetitle">
                    <span
                      style="font-size: 20px; color: orange; padding-top: 10px"
                      >指定分组:
                    </span>
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">{{ appointPartGrou }}</span>
                  </div>
                </li>
                <!-- 链接添加访客 -->
                <li v-if="checkout == 14">
                  <span class="codeDemoTitle">添加访客自定义信息</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL
                    里添加访客自定义信息。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >visitorInfo</span
                      >
                      <span style="padding-left: 10px"> 为访客信息</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "链接窗口接入地址"&visitorInfo='+data
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

var MKUserInfo='MKUserId:用户ID;MKUserName:用户名称;电话号码:13866666666;订单号码:2019121212666;';

var data=encodeURIComponent(MKUserInfo);

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,添加访客自定义信息为上面自定义的data-->
location.href='http://www.xxx.com?appId=xxxx&visitorInfo=data';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 15">
                  <span class="codeDemoTitle">指定客服</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL 里指定客服服务。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >uId</span
                      >
                      <span style="padding-left: 10px"> 为客服ID</span>
                    </li>
                  </ul>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,设置客服id为xxx-->
location.href='http://www.xxx.com?appId=xxxx&uId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 16">
                  <span class="codeDemoTitle">指定分组</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL 里指定客服组服务。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >gId</span
                      >
                      <span style="padding-left: 10px"> 为分组ID</span>
                    </li>
                  </ul>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,设置分组id为xxx-->
location.href='http://www.xxx.com?appId=xxxx&gId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 17">
                  <span class="codeDemoTitle">返回按钮</span>
                  <!-- 4.链接窗口地址-返回按钮是否显示: -->
                  <span class="codeDemoIllustrat"
                    >您可以通过该函数，传递不同参数来控制返回按钮是否展示</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >backBtn= 0</span
                      >
                      <span style="padding-left: 10px"> 为按钮隐藏</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >backBtn= 1</span
                      >
                      <span style="padding-left: 10px"> 为按钮展示</span>
                    </li>
                  </ul>

                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,显示返回按钮-->
location.href='http://www.xxx.com?appId=xxxx&backBtn=1';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 18">
                  <span class="codeDemoTitle">指定语言</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过传递不同的参数来指定语言服务</span
                  >
                  <span class="codeDemoIllustrate">* 语言说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >zhCN</span
                      >
                      <span style="padding-left: 10px"> 为简体中文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >enUS</span
                      >
                      <span style="padding-left: 10px"> 为英文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >vi</span
                      >
                      <span style="padding-left: 10px"> 为越南语</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "链接窗口接入地址"&lang=显示语言 zhCN,中文,默认显示
                      zhTW,繁体中文 enUS,英文 vi，越南语
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>

                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,设置语言为简体中文-->
location.href='http://www.xxx.com?appId=xxxx&lang=zhCN';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 19">
                  <span class="codeDemoTitle">指定访客ID</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来指定访客ID,访客的id不能超过32位</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >vId</span
                      >
                      <span style="padding-left: 10px"> 为访客ID</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "链接窗口接入地址"&vId=访客Id vId,访客Id(不能超过32位)
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,设置访客id为xxx-->
location.href='http://www.xxx.com?appId=xxxx&vId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 20">
                  <span class="codeDemoTitle">关闭按钮（链接2）</span>
                  <span class="codeDemoIllustrat"
                    >您可以通过该函数，传递不同参数来控制按钮是否展示</span
                  >
                  <!-- 7.链接窗口地址-关闭按钮是否显示(链接2): -->
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >closeBtn = 0</span
                      >
                      <span style="padding-left: 10px"> 为隐藏按钮</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >closeBtn = 1</span
                      >
                      <span style="padding-left: 10px"> 为展示按钮</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "链接窗口接入地址"&closeBtn=关闭按钮显示状
                      closeBtn=0,关闭按钮不显示 closeBtn=1,关闭按钮显示,默认显示
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.xxx.com?appId=xxxx ,关闭按钮显示-->
location.href='http://www.xxx.com?appId=xxxx&closeBtn=1';

</script>
                      "
                    />
                  </div>
                </li>
                <!-- 自定义域名添加访客 -->
                <li v-if="checkout == 21">
                  <span class="codeDemoTitle">添加访客自定义信息</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL
                    里添加访客自定义信息。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >visitorInfo</span
                      >
                      <span style="padding-left: 10px"> 为访客信息</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "自定义域名访问地址"&visitorInfo='+data
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

var MKUserInfo='MKUserId:用户ID;MKUserName:用户名称;电话号码:13866666666;订单号码:2019121212666';

var data=encodeURIComponent(MKUserInfo);

<!-- 如果：链接地址是http://www.域名.net,访客信息为上面自定义的data-->
location.href='http://www.域名.net?visitorInfo=data';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 22">
                  <span class="codeDemoTitle">指定客服</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL 里指定客服服务。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >uId</span
                      >
                      <span style="padding-left: 10px"> 为客服ID</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "自定义域名访问地址"&uId=客服Id uId,客服Id
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>


<!-- 如果：链接地址是http://www.域名.net,设置客服id为xxx-->
location.href='http://www.域名.net?uId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 23">
                  <span class="codeDemoTitle">指定分组</span>
                  <span class="codeDemoIllustrat"
                    >我们支持通过开放接口在对话链接 URL 里指定客服组服务。</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >gId</span
                      >
                      <span style="padding-left: 10px"> 为分组ID</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "自定义域名访问地址"&gId=分组Id gId,分组Id
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.域名.net,设置指定分组id为xxx-->
location.href='http://www.域名.net?gId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 24">
                  <span class="codeDemoTitle">返回按钮</span>
                  <span class="codeDemoIllustrat"
                    >您可以通过该函数，传递不同参数来控制返回按钮是否展示</span
                  >

                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >backBtn= 0</span
                      >
                      <span style="padding-left: 10px"> 为按钮隐藏</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >backBtn= 1</span
                      >
                      <span style="padding-left: 10px"> 为按钮展示</span>
                    </li>
                  </ul>
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.域名.net,显示返回按钮-->
location.href='http://www.域名.net?backBtn=1';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 25">
                  <span class="codeDemoTitle">显示语言</span>
                  <span class="codeDemoIllustrate"
                    >可以通过传递不同的参数来指定语言服务</span
                  >
                  <span class="codeDemoIllustrate">* 语言说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >zhCN</span
                      >
                      <span style="padding-left: 10px"> 为简体中文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >enUS</span
                      >
                      <span style="padding-left: 10px"> 为英文</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >vi</span
                      >
                      <span style="padding-left: 10px"> 为越南语</span>
                    </li>
                  </ul>

                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.域名.net,设置访客语言为简体中文-->
location.href='http://www.域名.net?lang=zhCN';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 26">
                  <span class="codeDemoTitle">指定访客ID</span>
                  <span class="codeDemoIllustrate"
                    >您可以通过该函数来指定访客ID,访客的id不能超过32位</span
                  >
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >vId</span
                      >
                      <span style="padding-left: 10px"> 为访客ID</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "自定义域名访问地址"&vId=访客Id vId,访客Id(不能超过32位)
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.域名.net,设置访客id为xxx-->
location.href='http://www.域名.net?vId=xxx';

</script>
                      "
                    />
                  </div>
                </li>
                <li v-if="checkout == 27">
                  <span class="codeDemoTitle">关闭按钮（链接2）</span>
                  <!-- 7.自定义域名-关闭按钮是否显示(链接2): -->
                  <span class="codeDemoIllustrat"
                    >您可以通过该函数，传递不同参数来控制按钮是否展示</span
                  >
                  <!-- 7.链接窗口地址-关闭按钮是否显示(链接2): -->
                  <span class="codeDemoIllustrate">* 参数说明</span>
                  <ul class="lugIllustrat">
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >closeBtn = 0</span
                      >
                      <span style="padding-left: 10px"> 为隐藏按钮</span>
                    </li>
                    <li>
                      <span
                        style="
                          width: auto;
                          height: auto;
                          padding: 3px;
                          background: #f5f5f5;
                          color: red;
                        "
                        >closeBtn = 1</span
                      >
                      <span style="padding-left: 10px"> 为展示按钮</span>
                    </li>
                  </ul>
                  <!-- <div class="codetitle">
                    <span class="codeDemoTitle">接口函数：</span>
                    <span class="codeMain">
                      "自定义域名访问地址"&closeBtn=关闭按钮显示状
                      closeBtn=0,关闭按钮不显示 closeBtn=1,关闭按钮显示,默认显示
                    </span>
                  </div> -->
                  <span class="codeDemoTitle">代码示范： </span>
                  <div class="codeShow">
                    <highlightjs
                      language="html"
                      code="
<script type='text/javascript'>

<!-- 如果：链接地址是http://www.域名.net,显示关闭按钮-->
location.href='http://www.域名.net?closeBtn=1';

</script>
                      "
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 试用 -->
    <div class="bottom">
      <on-trial-vue />
    </div>
  </div>
</template>
<script>
import OnTrialVue from "@/components/OnTrial.vue";
export default {
  metaInfo: {
    title: "开发者中心 | 小美客服系统",

    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    OnTrialVue,
  },
  data() {
    return {
      selectData: [
        {
          label: "访客自定义信息添加",
          id: 0,
          children: [
            {
              label: "JS控件",
              id: 1,
              children: [
                {
                  label: "设置用户信息",
                  id: 4,
                },
                {
                  label: "设置用户ID",
                  id: 5,
                },
                {
                  label: "设置用户名称",
                  id: 6,
                },
                {
                  label: "添加访客自定义信息",
                  id: 7,
                },
                {
                  label: "指定客服",
                  id: 8,
                },
                {
                  label: "指定分组",
                  id: 9,
                },
                {
                  label: "指定语言",
                  id: 10,
                },
                {
                  label: "指定访客ID",
                  id: 11,
                },
                {
                  label: "控件隐藏浮动按钮",
                  id: 12,
                },
                {
                  label: "自定义调用按钮",
                  id: 13,
                },
              ],
            },
            {
              label: "对话链接",
              id: 2,
              children: [
                {
                  label: "添加访客自定义信息",
                  id: 14,
                },
                {
                  label: "指定客服",
                  id: 15,
                },
                {
                  label: "指定分组",
                  id: 16,
                },
                {
                  label: "返回按钮",
                  id: 17,
                },
                {
                  label: "指定展示语言",
                  id: 18,
                },
                {
                  label: "指定访客ID",
                  id: 19,
                },
                {
                  label: "关闭按钮（链接2）",
                  id: 20,
                },
              ],
            },
            {
              label: "自定义域名",
              id: 3,
              children: [
                {
                  label: "添加访客自定义信息",
                  id: 21,
                },
                {
                  label: "指定客服",
                  id: 22,
                },
                {
                  label: "指定分组",
                  id: 23,
                },
                {
                  label: "返回按钮",
                  id: 24,
                },
                {
                  label: "指定展示语言",
                  id: 25,
                },
                {
                  label: "指定访客ID",
                  id: 26,
                },
                {
                  label: "关闭按钮（链接2）",
                  id: 27,
                },
              ],
            },
          ],
        },
      ],
      checkout: "4",
      code: "",
      chatbegin: "<a href='javascript:_MKEFU.openMessageDialog(true);'>",
      appointkefu:
        "<a href='javascript:_MKEFU.openMessageDialogWithAgent('agentId');'>",
      appointPartGrou:
        "<a href='javascript:_MKEFU.openMessageDialogWithGroup('groupId');'>",
      leftList: [
        { label: "JS控件添加访客自定义信息" },
        { label: "链接添加访客自定义信息" },
        { label: "自定义域名添加访客自定义信息" },
      ],
      index: 0,
    };
  },
  methods: {
    handleNodeClick(data) {
      debugger;
      switch (data.id) {
        case 0:
          this.checkout = 4;
          break;
        case 1:
          this.checkout = 4;
          break;
        case 2:
          this.checkout = 14;
          break;
        case 3:
          this.checkout = 21;
          break;
        default:
          this.checkout = data.id;
      }
      console.log("我看下id", data.id);
    },
    menuItemClick(val) {
      this.index = val;
    },
  },
};
</script>
<style></style>
<style scoped lang="less">
::v-deep .el-tree {
  background: #fafafb;
}
// ::v-deep .hljs {
//   // color: #3d3d3d;
//   /* background: #282c34; */
//   background: #f5f5f5;
// }
.question {
  width: 100%;
  padding-top: 0px;
  background-color: #fafafb;
}

.bottom {
  margin-top: 80px;
}

.top {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
  }
}

.top-box {
  @media screen and (max-width: 700px) {
    display: flex;
    margin-left: 10px;
  }
}

.top-button {
  width: 180px;
  outline: none;
  height: 70px;
  background-color: #fff;
  border-radius: 8px;
  color: #676d83;
  border: none;
  font-size: 22px;
  margin-right: 30px;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 100px;
    font-size: 18px;
    height: 50px;
    margin-right: 20px;
  }
}

.state {
  width: 566px;
  height: 39px;
}

.meun {
  overflow-y: auto;
  padding-left: 10px;
  padding-top: 20px;
  width: 20%;
  background-color: #fafafb;
  float: left;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
  }
}

.end {
  display: flex;
  justify-content: space-between;
}

.end-box-dev {
  width: 100%;
  height: 706px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  // display: flex;
  // justify-content: space-between;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
    // display: table;
    // clear: both;
  }
}

::v-deep .el-menu {
  border: none;
  padding-left: 0px;
}

::v-deep .el-menu-item {
  height: 70px;
  color: #fff;
  line-height: 70px;
  font-size: 20px;

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

::v-deep .el-menu-item.is-active {
  background-color: #fff !important;
  color: #020f3b;
}

::v-deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #020f3b;
}

.content {
  width: 76%;
  height: 100%;
  padding: 20px 30px 20px 0;
  float: right;
  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 0;
    display: block;
  }
}

.content-title {
  color: #021b64;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (max-width: 700px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.content-text {
  height: 650px;
  // border: 1px solid #021b64;
  padding: 0 80px 0 10px;
  border-radius: 2%;
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
  overflow-y: auto;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}
.text_main {
  .codetitle {
    width: 100%;
    .codeMain {
      width: auto;
      height: auto;
      padding: 10px;
      background: #f5f5f5;
      color: red;
    }
  }
  .codeDemoTitle {
    padding: 10px;
    font-size: 25px;
    font-weight: 800;
    color: black;
  }
  .codeDemoIllustrate {
    padding: 15px;
    font-size: 18px;
    font-weight: 400;
    color: black;
  }
  .lugIllustrat {
    color: black;
    li {
      list-style: disc !important;
      color: black;
      display: flex;
    }
  }
  .codeShow {
    width: auto;
    height: auto;
    padding: 10px;
    background: #f5f5f5;
  }
  li {
    font-weight: 800;
    list-style: none;
  }
  span {
    display: block;
    color: black;
    font-weight: 400;
  }
}
</style>
