<template>
  <div class="customer">
    <!-- 背景 -->
    <div class="customer-background">
      <!-- <img src="@/assets/customer/bannerBase.png" /> -->
      <!-- 背景文字 -->
      <div class="customer-title">
        <h1 class="customer-big-title">在线客服</h1>
        <h2 class="customer-little-title">
          多端口集成统一对话窗口，快速人机协作，提高效率
        </h2>
      </div>
      <!-- 背景插图 -->
      <!-- <div class="customer-pic">
        <img src="@/assets/customer/bannerCustomer.png" />
      </div> -->
    </div>
    <!-- 中间方块 -->
    <div class="customer-main">
      <!-- <div class="customer-main-4"></div> -->
      <div class="main">
        <div class="customer-main-2">
          <div class="img img1">
            <img src="@/assets/customer/customerBg1.png" alt="多渠道整合" />
          </div>
          <div class="img img2">
            <img src="@/assets/customer/customerBg2.png" alt="数据报告" />
          </div>
          <div class="img img3">
            <img src="@/assets/customer/customerBg3.png" alt="机器人" />
          </div>
        </div>
        <div class="customer-main-3">
          <div class="img img4">
            <img src="@/assets/customer/customerBg4.png" alt="云服务" />
          </div>
          <div class="img img5">
            <img src="@/assets/customer/customerBg5.png" alt="ai智能" />
          </div>
          <div class="img img6">
            <img src="@/assets/customer/customerBg6.png" alt="界面自定义" />
          </div>
        </div>
      </div>
    </div>
    <!-- 更多特色功能 -->
    <div class="bottom">
      <div>
        <div class="bottom-title">更多特色功能</div>
        <more-vue></more-vue>
      </div>
    </div>
    <!-- 多企业 -->
    <div>
      <multienter-prise-vue />
    </div>
    <!-- 试用 -->
    <div>
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import OnTrialVue from "@/components/OnTrial.vue";
import MultienterPriseVue from "@/components/MultienterPrise.vue";
import MoreVue from "./more.vue";
export default {
  metaInfo: {
    title: "在线客服 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    OnTrialVue,
    MultienterPriseVue,
    MoreVue,
  },
};
</script>

<style scoped lang="less">
.img1 img {
  width: 620px;
  height: 479px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 335px;
  }
}

.img {
  margin-top: -60px;

  @media screen and (max-width: 700px) {
    margin: -40px 0;
  }
}

.img2 img {
  width: 620px;
  height: 403px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 275px;
  }
}

.img3 img {
  width: 620px;
  height: 358px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 258px;
  }
}

.img4 img {
  width: 620px;
  height: 428px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 290px;
  }
}

.img5 img {
  width: 620px;
  height: 392px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 265px;
  }
}

.img6 img {
  width: 620px;
  height: 421px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 285px;
  }
}

.customer-main-3 {
  @media screen and (max-width: 700px) {
    margin-top: 40px;
  }
}

.customer {
  width: 100%;
}

.customer-main {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  position: relative;
}

.main {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.bottom-title {
  color: #020c30;
  font-size: 38px;
  text-align: center;
  padding-bottom: 20px;

  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.bottom {
  display: flex;
  justify-content: center;
  padding-top: 80px;

  @media screen and (max-width: 700px) {
    align-items: center;
    padding-bottom: 30px;
  }
}
.customer-background {
  width: 100%;
  height: 644px;
  background-image: url("@/assets/customer/banner_03.png");
  background-size: 107% 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
    height: 200px;
    background-image: url("@/assets/customer/banner_03.png");
    background-size: 107% 100%;
  }
}
.customer-background img {
  height: 644px;
  width: 100%;

  @media screen and (max-width: 700px) {
    height: 184px;
  }
}

.customer-big-title {
  color: #fff;
  font-size: 52px;
  margin-bottom: 32px;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.customer-little-title {
  color: #fff;
  opacity: 80%;
  font-size: 19px;
  font-weight: 400;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.customer-background {
  position: relative;

  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
}

.customer-title {
  position: absolute;
  top: 35%;
  left: 11%;

  @media screen and (max-width: 700px) {
    top: 35%;
    left: 10%;
  }
}
// .customer-pic {
//   width: 1000px;
//   position: absolute;
//   top: 10%;
//   right: 10%;

//   @media screen and (max-width: 700px) {
//     width: 1000px;
//     top: 10%;
//     right: 10%;
//   }
// }
.customer-main-4 {
  width: 200px;
  height: 674px;
  padding: 50px 20px;
  background-color: #112d82ff;
  margin-top: 130px;
  float: right;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: 700px) {
    display: none;
  }
}
</style>
