import { createRouter, createWebHistory } from "vue-router";
import Layout from "../layout/HeaderList.vue";
import customer from "../views/customer";
import workorder from "../views/workorder";
import robot from "../views/robot";
import question from "../views/question";
import statement from "../views/statement";
import Tiktok from "../views/Tiktok";
import join from "../views/join";
import price from "../views/price";
import agreement from "../views/agreement";
import developer from "../views/developer";
import journal from "../views/journal";
import center from "../views/center";
import contact from "../views/contact";
import quotation from "../views/quotation";
import Applet from "../views/Applet";
import honor from "../views/honor";
import web from "../views/web";
import app from "../views/app";
import wechat from "../views/wechat";
import home from "../views/home";
import official from "../views/official";
import introduce from "../views/introduce";

const routes = [
  // 登录
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("@/views/login/index.vue"),
  //   meta: {
  //     title: "login",
  //   },
  // },
  // layout
  {
    path: "/",
    component: Layout,
    name: "Layout",
    redirect: "/home",
    children: [
      // 首页
      {
        path: "home",
        name: "home",
        component: home
      },
      // 在线客服
      {
        path: "customer",
        name: "customer",
        component: customer
      },
      // 工单系统
      {
        path: "workorder",
        name: "workorder",
        component: workorder
      },
      // 智能机器人
      {
        path: "robot",
        name: "robot",
        component: robot
      },

      // 价格
      {
        path: "price",
        name: "price",
        component: price
      },
      
      // 常见问题
      {
        path: "question",
        name: "question",
        component: question
      },
      // 隐私声明
      {
        path: "statement",
        name: "statement",
        component: statement
      },
      // 用户协议
      {
        path: "agreement",
        name: "agreement",
        component: agreement
      },
      // 开发者中心
      {
        path: "developer",
        name: "developer",
        component: developer
      },
      // 更新日志
      {
        path: "journal",
        name: "journal",
        component: journal
      },
       // 下载中心
       {
        path: "center",
        name: "center",
        component: center
      },
       // 公司简介
       {
        path: "introduce",
        name: "introduce",
        component: introduce
      },
       // 资质荣誉
       {
        path: "honor",
        name: "honor",
        component: honor
      },
       // 联系我们
       {
        path: "contact",
        name: "contact",
        component: contact
      },
      // 加入我们
      {
        path: "join",
        name: "join",
        component: join
      },
      // 行情资讯
      {
        path: "quotation",
        name: "quotation",
        component: quotation
      },
      // 小程序
      {
        path: "Applet",
        name: "Applet",
        component: Applet
      },
      // 网页客服
      {
        path: "web",
        name: "web",
        component: web
      },
       // 企业微信
       {
        path: "wechat",
        name: "wechat",
        component: wechat
      },
       // app
       {
        path: "app",
        name: "app",
        component: app
      },
       // 抖音
       {
        path: "Tiktok",
        name: "Tiktok",
        component: Tiktok
      },
      // 公众号
      {
        path: "official",
        name: "official",
        component: official
      },
    ],
  },
];


  

const router = createRouter({
  // mode:'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to,from,savedPosition) {

    if (savedPosition){
    
    return savedPosition // 按下 后退/前进 按钮时，类似浏览器的原生表现
    
    }else {
    
    return {x:0,y:0} // 让页面滚动到顶部
    
    }
    
    }
});

export default router;
