<template>
  <div>
    <div class="top">
      <img src="@/assets/introduce/i1.png" />
      <div class="top-box">
        <h1 class="title">加入我们</h1>
        <div class="content">
          <div class="left">
            <div class="left-title">销售总监</div>
            <div class="left-text">
              <p>1、指定产品销售计划和战略</p>
              <p>2、领导部门完成推广、销售和服务工作</p>
              <p>3、负责销售员工的组织和管理</p>
              <p>4、进行渠道建设规划</p>
              <p>5、加强客户管理，建设完善的销售管理体系</p>
              <p>6、捕捉用户需求，促进产品改进和新品研发</p>
            </div>
            <div class="left-text">
              <p>职位要求</p>
              <p>1、大专及以上学历</p>
              <p>2、3年以上互联网产品销售经验</p>
              <p>3、具有丰富的客户资源和客户关系,业绩优秀</p>
            </div>
            <!-- <div class="left-text">
              <p>简历投递至：xxxxxxxxxxxx</p>
            </div> -->
          </div>
          <div class="left">
            <div class="left-title">产品经理</div>
            <div class="left-text">
              <p>1、产品调研和策划</p>
              <p>2、需求分析和产品设计</p>
              <p>3、项目管理</p>
              <p>4、用户体验和界面设计</p>
              <p>5、市场推广和销售支持</p>
              <p>6、数据分析和评估</p>
            </div>
            <div class="left-text">
              <p>职位要求</p>
              <p>1、本科及以上学历</p>
              <p>2、3年以上互联网产品经验</p>
              <p>3、熟练axure、visio、office等工具</p>
            </div>
            <!-- <div class="left-text">
              <p>简历投递至：xxxxxxxxxxxx</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "加入我们 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
};
</script>
<style scoped lang="less">
.top {
  height: 1000px;
  width: 100%;
  position: relative;

  @media screen and (max-width: 700px) {
    height: 1300px;
  }
}

.top img {
  width: 100%;
  height: 432px;

  @media screen and (max-width: 700px) {
    height: 200px;
  }
}

.top-box {
  width: 1200px;
  height: 664px;
  background-color: #fff;
  position: absolute;
  top: 216px;
  left: 50%;
  margin-left: -600px;
  padding-bottom: 20px;

  @media screen and (max-width: 700px) {
    width: 420px;
    height: 1114px;
    margin-left: -210px;
    top: 110px;
  }
}

.content {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.left {
  width: 514px;
  margin: 0 20px 20px 20px;
  padding-bottom: 20px;
  height: 474px;
  background-color: rgba(48, 79, 174, 0.05);
  // text-align: center;

  @media screen and (max-width: 700px) {
    width: 380px;
  }
}

.title {
  color: #021b64;
  font-size: 34px;
  text-align: center;
  margin: 40px 0;
  font-weight: 500;
}

.left-title {
  color: #021b64;
  font-size: 22px;
  margin: 20px 0;
  // text-align: center;
  padding-left: 20px;
}

.left-text {
  color: #021b64;
  opacity: 0.7;
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.left-text p {
  padding: 0 0;
}
</style>
