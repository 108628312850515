<template>
  <div class="main">
    <el-row :gutter="20">
      <el-col :span="6" id="card1">
        <el-card
          shadow="hover"
          @mouseover="mouseover1"
          @mouseleave="mouseleave1"
        >
          <!-- 1 -->
          <div class="price-part1" id="part1">
            <div class="price-title">标准版</div>
            <div class="price-describe">
              适合小微初创企业，注册即用，简单方便
            </div>
            <div class="price-price">
              <div>
                <span>￥</span>
                <span>1688</span>
                <span class="price-price-last-child">/坐席/年</span>
              </div>
              <el-link
                href="https://regist.chat5188.com/"
                :underline="false"
                target="_blank"
              >
                <button>免费试用</button>
              </el-link>
            </div>

            <div class="price-details">
              <p>✓ 多渠道接入频道等丰富消息体</p>
              <p>✓ 访客端样式设置</p>
              <p>✓ 会话记录永久保存</p>
              <p>✓ 会话邀请、分配、排队、转接</p>
              <p>✓ 满意度评价与服务小计</p>
              <p>✓ 留言分配与处理</p>
              <p>✓ 客户标签设置</p>
              <p>✓ 访客正在输入消息预查看</p>
              <p>✓ 快捷回复常用语</p>
              <p>✓ 主动发起回话</p>
              <p>✓ 实时、历史数据报表</p>
              <p>✓ 短信服务（需充短信费）</p>
              <p>✓ 客户中心</p>
              <p>✓ 网页/PC/移动端工作台</p>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 2 -->
      <el-col :span="6" id="card2">
        <el-card
          shadow="hover"
          @mouseover="mouseover2"
          @mouseleave="mouseleave2"
        >
          <div class="price-part2" id="part2">
            <div class="price-title">专业版</div>
            <div class="price-describe">
              适合中小型企业，满足售前售后，全场景功能需求
            </div>
            <div class="price-price">
              <div>
                <span>￥</span>
                <span>3888</span>
                <span class="price-price-last-child">/坐席/年</span>
              </div>
              <el-link
                href="https://regist.chat5188.com/"
                :underline="false"
                target="_blank"
              >
                <button style="background: #788eab; border-color: #788eab">
                  免费试用
                </button>
              </el-link>
            </div>
            <div class="price-details">
              <p>✓ 包含标准版功能</p>
              <p>✓ 访客轨迹</p>
              <p>✓ 服务小计</p>
              <p>✓ 不限最大接待量</p>
              <p>✓ 消息撤回</p>
              <p>✓ 坐席绩效考核</p>
              <p>✓ 访问统计</p>
              <p>✓ 个人客户管理</p>
              <p>✓ 内部知识库</p>
              <p>✓ 智能机器人</p>
              <p>✓ 个人定制服务</p>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 3 -->
      <el-col :span="6" id="card3">
        <el-card
          shadow="hover"
          @mouseover="mouseover3"
          @mouseleave="mouseleave3"
        >
          <div class="price-part3" id="part3">
            <div class="price-title">旗舰版</div>
            <div class="price-describe">
              适合中大型企业，满足企业更高的业务需求
            </div>
            <div class="price-price">
              <div>
                <span>￥</span>
                <span>5888</span>
                <span class="price-price-last-child">/坐席/年</span>
              </div>
              <el-link
                href="https://regist.chat5188.com/"
                :underline="false"
                target="_blank"
              >
                <button style="background: #c49551; border-color: #c49551">
                  免费试用
                </button>
              </el-link>
            </div>
            <div class="price-details">
              <p>✓ 包含专业版功能</p>
              <p>✓ 防cc攻击</p>
              <p>✓ 防ddos攻击</p>
              <p>✓ 多线路支持</p>
              <p>✓ 多域名支持</p>
              <p>✓ 独享优质服务资源</p>
              <p>✓ 支持语音短消息</p>
              <p>✓ 支持视频短消息</p>
              <p>✓ 支持AI语音识别</p>
              <p>✓ 支持个人定制服务</p>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 4 -->
      <el-col :span="6" id="card4">
        <el-card
          shadow="hover"
          @mouseover="mouseover4"
          @mouseleave="mouseleave4"
        >
          <div class="price-part4" id="part4">
            <div
              class="price-title"
              style="
                background: linear-gradient(to bottom, #fffad4, #f8ca9f);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              "
            >
              私有部署
              <!-- <span>(含买新)</span> -->
            </div>
            <div
              class="price-describe"
              style="
                background: linear-gradient(to bottom, #fffad4, #f8ca9f);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              "
            >
              适合有数据私有化管理需要的企业和政府事业单位， 满足数据安全需求
            </div>
            <div class="price-price">
              <div>
                <span
                  class="price-price-child"
                  style="
                    font-family: PingFangSC-Semibold;
                    font-size: 42px;
                    color: #ffffff;
                    font-weight: 600;
                  "
                  >咨询客服</span
                >
              </div>
              <el-link
                href="https://regist.chat5188.com/"
                :underline="false"
                target="_blank"
              >
                <button style="background: #4a4268; border-color: #4a4268">
                  免费试用
                </button>
              </el-link>
            </div>
            <div class="price-details">
              <p>✓ 包含当前版本功能</p>
              <p>✓ 私有化部署</p>
              <p>✓ 可定制化</p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
const mouseover1 = () => {
  document.getElementById("part1").style.height = 1174 + "px";
  document.getElementById("card1").style.marginTop = -30 + "px";
};
const mouseleave1 = () => {
  document.getElementById("part1").style.height = 1144 + "px";
  document.getElementById("card1").style.marginTop = 0 + "px";
};
const mouseover2 = () => {
  document.getElementById("part2").style.height = 1174 + "px";
  document.getElementById("card2").style.marginTop = -30 + "px";
};
const mouseleave2 = () => {
  document.getElementById("part2").style.height = 1144 + "px";
  document.getElementById("card2").style.marginTop = 0 + "px";
};
const mouseover3 = () => {
  document.getElementById("part3").style.height = 1174 + "px";
  document.getElementById("card3").style.marginTop = -30 + "px";
};
const mouseleave3 = () => {
  document.getElementById("part3").style.height = 1144 + "px";
  document.getElementById("card3").style.marginTop = 0 + "px";
};
const mouseover4 = () => {
  document.getElementById("part4").style.height = 1174 + "px";
  document.getElementById("card4").style.marginTop = -30 + "px";
};
const mouseleave4 = () => {
  document.getElementById("part4").style.height = 1144 + "px";
  document.getElementById("card4").style.marginTop = 0 + "px";
};
</script>
<style scoped>
.price-part1 {
  width: 320px;
  height: 1144px;
  overflow: hidden;
  text-align: center;
  /* background: #666; */
  margin-top: -20px;
  background-image: url("@/assets/price/base01.png");
  /* background-attachment: fixed; */
  background-size: cover cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.price-part2 {
  width: 320px;
  height: 1144px;
  overflow: hidden;
  text-align: center;
  /* background: #666; */
  margin-top: -20px;
  background-image: url("@/assets/price/base2.png");
  /* background-attachment: fixed; */
  background-size: cover cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.price-part3 {
  width: 320px;
  height: 1144px;
  overflow: hidden;
  text-align: center;
  /* background: #666; */
  margin-top: -20px;
  background-image: url("@/assets/price/base3.png");
  /* background-attachment: fixed; */
  background-size: cover cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.price-part4 {
  width: 320px;
  height: 1144px;
  overflow: hidden;
  text-align: center;
  /* background: #666; */
  margin-top: -20px;
  background-image: url("@/assets/price/base4.png");
  /* background-attachment: fixed; */
  background-size: cover cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.el-col {
  padding: 0 !important;
}
.el-col:hover {
  z-index: 1;
}
.el-card {
  height: 1070px;
  --el-card-border-radius: 5px;
  --el-card-padding: 0px;
}

.el-card:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 20, 85, 0.2);
}
.price-title {
  font-family: PingFangSC-Semibold;
  font-size: 34px;
  color: #020d30;
  font-weight: 600;
  margin: 46px 0 5px 0;
}

.price-title span {
  font-size: 20px;
}
.price-describe {
  border: none;
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #626b83;
  font-weight: 400;
  height: 90px;
  margin: 0 30px;
  padding-top: 36px;
}

.price-details {
  border: none;
  padding-bottom: 20px;
  height: 700px;
  margin: 70px 30px 10px 30px;
}

.price-details p {
  text-align: left;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #626b83;
  margin: 10px 0;
}

.price-price {
  padding-top: 16px;
  font-family: PingFangSC-Semibold;
  font-size: 42px;
  color: #020d30;
  font-weight: 600;
}

.price-price span:first-child {
  font-size: 16px;
}

.price-price-last-child {
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #626b83;
  font-weight: 400;
}

.price-price-child {
  font-size: 26px !important;
}

.price-price button {
  background: #6a788d;
  border-radius: 8px;
  width: 271px;
  height: 64px;
  border: 1px solid #6a788d;
  /* border-radius: 5px; */
  color: #fff;
  font-size: 20px;
  outline: none;
  margin-top: 43px;
  cursor: pointer;
}
</style>
