<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    //   (function(x, i, ao, m, ei, j, s) {
    //   x[m] = x[m] || function() {(x[m].ei = x[m].ei || []).push(arguments)};
    //   j = i.createElement(ao),s = i.getElementsByTagName(ao)[0];
    //   j.async = true;
    //   j.charset = 'UTF-8';
    //   j.id="MKEFU_WEBPLUGIN";
    //   j.src='https://one.chat5188.com/js/mkefu.min.js';
    //   s.parentNode.insertBefore(j, s);
    // })(window, document, 'script', '_MKEFU');
    // _MKEFU('appId', 'd1d75de3f60a4d82ae3a2f25e89e820d');
  },
};
</script>
