<template>
  <div class="up">
    <el-popover placement="left" :width="200" trigger="hover">
      <template #reference>
        <a href="javascript:_MKEFU.openMessageDialog(true);">
          <div class="box pointer">
            <img src="@/assets/up/up1.png" alt="在线咨询" />
            <p class="popover-text">在线咨询</p>
          </div>
        </a>
      </template>
      <template #default>
        <div>
          <div class="title">解决您的客户服务需求</div>
          <div class="content">您的需求就是我们的专业</div>
        </div>
      </template>
    </el-popover>
    <el-popover placement="left" :width="100" trigger="hover">
      <template #reference>
        <div class="box">
          <img src="@/assets/up/up3.png" alt="关注微信" />
          <p class="popover-text no-pointer">关注微信</p>
        </div>
      </template>
      <template #default>
        <div class="demo-rich-conent">
          <img src="@/assets/up/wecatCode.png" alt="企业微信" />
        </div>
      </template>
    </el-popover>
    <el-popover placement="left" trigger="hover" content="QQ咨询">
      <template #reference>
        <div class="box">
          <img src="@/assets/up/up4.png" alt="QQ咨询" />
          <p class="popover-text no-pointer">QQ咨询</p>
        </div>
      </template>
      <template #default>
        <div class="demo-rich-conent">
          <div>3352825133</div>
          <div>3486282780</div>
        </div>
      </template>
    </el-popover>

    <el-backtop right="0" bottom="20">
      <div class="backtop">
        <i class="iconfont icon-shengdanjianhuahuidaodingbu"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const visible = ref(false);
onMounted(() => {});
</script>
<style scoped>
.up {
  position: fixed;
  top: 180px;
  right: 0;
  z-index: 5;
  cursor: default;
}
.box {
  width: 90px;
  height: 90px;
  background-color: #223675;
  text-align: center;
  padding-bottom: 15px;
}
.box-bottom {
  margin-top: 10px;
}
.box img {
  width: 36px;
  height: 36px;
  margin-top: 10px;
}
.box p {
  color: #fff;
  opacity: 0.8;
  font-size: 17px;
}
.el-backtop {
  width: 90px;
  height: 90px;
}
.backtop {
  width: 90px;
  height: 90px;
  background-color: #223675;
  box-shadow: var(--el-box-shadow-lighter);
  text-align: center;
  line-height: 90px;
  color: #fff;
}

.icon-shengdanjianhuahuidaodingbu {
  font-size: 40px;
  margin-left: 8px;
}
.demo-rich-conent img {
  width: 80px;
  height: 80px;
}

.demo-rich-conent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
}
.title {
  font-size: 17px;
  font-weight: bold;
}
.content {
  font-size: 15px;
}
.pointer {
  cursor: pointer;
}
.no-pointer {
  cursor: default;
}
</style>
