<template>
  <div class="home">
    <!-- 轮播图 -->
    <el-carousel>
      <el-carousel-item v-for="item in list" :key="item.id">
        <div class="home-bgimg">
          <div class="home-left">
            <h1 class="home-title">{{ item.title }}</h1>
            ``
            <div class="home-content" v-html="item.content"></div>
            <el-link
              href="https://regist.chat5188.com/"
              :underline="false"
              target="_blank"
            >
              <button>立即免费体验</button>
            </el-link>
          </div>
          <div class="home-right">
            <img :src="item.img" />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 圆圈百分比 -->
    <div class="center-one">
      <div class="center-one-title">提高交易转化率，提升客户满意度备份</div>
      <div class="center-one-little-title">
        极简的使用体验，尊贵的服务态度，我们100%竭诚为您提供最需要的功能
      </div>
      <div class="center-one-img">
        <div>
          <img :src="homeImg1" />
          <div class="center-one-img-title">提升客服效率</div>
          <div class="center-one-img-text">
            全渠道一体化工作台<br />
            PC+移动双端工作<br />
            人机协作，客服工作不再难
          </div>
        </div>
        <div>
          <img :src="homeImg1" />
          <div class="center-one-img-title">降低管理成本</div>
          <div class="center-one-img-text">
            全局会话实时监控<br />
            综合多种数据维度报表<br />
            一键接入机器人/客服对话
          </div>
        </div>
        <div>
          <img :src="homeImg2" />
          <div class="center-one-img-title">提升客户满意度</div>
          <div class="center-one-img-text">
            四位一体保障客户满意度<br />
            自动人机转接方式<br />
            避免客户流失，锁定商机
          </div>
        </div>
        <div>
          <img :src="homeImg3" />
          <div class="center-one-img-title">节省人工成本</div>
          <div class="center-one-img-text">
            智能机器人快速解决客户问题<br />
            识别率高达90%以上<br />
            年节省人工成本可达100000+
          </div>
        </div>
      </div>
    </div>
    <div class="home-center">
      <home-vue></home-vue>
    </div>
    <!-- 多企业 -->
    <multienter-prise-vue />
    <!-- 试用 -->
    <on-trial-vue />
  </div>
</template>

<script>
import HomeVue from "./home";
import OnTrialVue from "@/components/OnTrial.vue";
import MultienterPriseVue from "@/components/MultienterPrise.vue";
// 图片
import homeImg1 from "@/assets/home/homeImg1.png";
import homeImg2 from "@/assets/home/homeImg2.png";
import homeImg3 from "@/assets/home/homeImg3.png";
import homebg from "@/assets/home/homebg.png";
export default {
  metaInfo: {
    title: "首页 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    MultienterPriseVue,
    OnTrialVue,
    HomeVue,
  },
  data() {
    return {
      list: [
        {
          id: 1,
          title: "全场景智能客服系统平台",
          content:
            "集网站在线、微信公众号、微信小程序、微博客服系统等功能于一身<br />的客服系统，可实现多渠道对话接入，统一对话窗口，统一客服管理界面",
          img: homebg,
        },
      ],
      homeImg1: homeImg1,
      homeImg2: homeImg2,
      homeImg3: homeImg3,
    };
  },
};
</script>
<style scoped lang="less">
::v-deep .el-carousel__container {
  height: 776px !important;

  @media screen and (max-width: 700px) {
    height: 476px !important;
  }
}

::v-deep .el-carousel__item {
  height: 776px !important;

  @media screen and (max-width: 700px) {
    height: 476px !important;
  }
}

.home-bgimg {
  background: -webkit-linear-gradient(left, #00269a, #020c2f);
  height: 776px;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 700px) {
    height: 476px;
  }
}

.home-title {
  color: #ffffff;
  font-size: 52px;
  padding-bottom: 10px;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 35px;
  }
}

.home-content {
  color: #fff;
  font-size: 19px;
  opacity: 80%;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

.home-left {
  position: absolute;
  top: 45%;
  margin-top: -90px;
  left: 15%;
  margin-left: -86px;

  @media screen and (max-width: 700px) {
    z-index: 2;
    top: 45%;
  }
}

.home-right {
  position: absolute;
  top: 50%;
  margin-top: -224px;
  left: 75%;
  margin-left: -286px;

  @media screen and (max-width: 700px) {
    position: absolute;
    top: 100%;
    margin-top: -228px;
    left: 100%;
    margin-left: -272px;
  }
}

.home-left button {
  background: -webkit-linear-gradient(top, #9de3ff, #6cd6ff);
  font-size: 22px;
  width: 214px;
  height: 48px;
  outline: none;
  border-radius: 8px;
  border: none;
  line-height: 48px;
  margin-top: 30px;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    font-size: 15px;
    width: 154px;
    height: 38px;
    line-height: 38px;
  }
}

.home-right img {
  width: 572px;
  height: 448px;

  @media screen and (max-width: 700px) {
    width: 272px;
    height: 178px;
  }
}

.center-one {
  padding-top: 80px;
  text-align: center;

  @media screen and (max-width: 700px) {
  }
}

.center-one-title {
  color: #020c30;
  font-size: 38px;
  padding-bottom: 5px;

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
}

.center-one-little-title {
  color: #020c30;
  font-size: 20px;
  opacity: 60%;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

.center-one-img {
  display: flex;
  justify-content: center;
  padding-top: 50px;

  @media screen and (max-width: 700px) {
  }
}

.center-one-img:nth-child(3) {
  @media screen and (max-width: 700px) {
    display: block;
  }
}

.center-one-img img {
  width: 140px;
  padding: 30px 60px 50px 60px;
  height: 140px;

  @media screen and (max-width: 700px) {
    width: 100px;
    padding: 30px 0px 30px 0px;
    height: 100px;
  }
}

.center-one-img-title {
  color: #021b64;
  font-size: 22px;
  font-family: PingFangSC-Medium, sans-serif !important;
  font-weight: bold;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.center-one-img-text {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
  padding-top: 20px;

  @media screen and (max-width: 700px) {
    font-size: 15px;
    padding-bottom: 30px;
  }
}
</style>
