<template>
  <div>
    <!-- 多家企业 -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">多家企业的信赖选择</div>
        <div class="center-two-little-title">
          极简的使用体验，尊贵的服务态度，我们100%竭诚为您提供最需要的服务
        </div>
      </div>
      <div class="center-two-img">
        <img src="@/assets/home/homeImgEnd.png" alt="合作企业" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.center-two-title {
  color: #020c30;
  font-size: 38px;
  padding-bottom: 10px;

  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.center-two-little-title {
  color: #020c30;
  font-size: 20px;
  opacity: 60%;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

.center-two-img img {
  width: 1201px;
  height: 583px;

  @media screen and (max-width: 700px) {
    width: 350px;
    height: 170px;
  }
}

.center-two-little-title {
  width: 400px;
}

.center-two-top {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  top: 80px;

  @media screen and (max-width: 700px) {
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 25px;
  }
}

.center-two {
  padding-top: 80px;
  text-align: center;
  position: relative;
  background-color: #f4f7ff;
  @media screen and (max-width: 700px) {
    padding-top: 55px;
    padding-bottom: 40px;
  }
}
</style>
