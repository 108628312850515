<template>
  <div class="work-part">
    <div class="work">
      <img src="@/assets/robot/r1.png" alt="连锁问答服务" />
      <p class="work-title">连锁问答服务</p>
      <div class="work-content">
        定制化服务，通过与用户的多轮交互，实现快递查询、订餐、医生预诊等多种服务类功能，满足不同业务需求，为更多的服务属性企业实现智能化的改造
      </div>
    </div>
    <div class="work">
      <img src="@/assets/robot/r2.png" alt="业务咨询服务" />
      <p class="work-title">业务咨询服务</p>
      <div class="work-content">
        定制化服务，通过与用户的多轮交互，实现快递查询、订餐、医生预诊等多种服务类功能，满足不同业务需求，为更多的服务属性企业实现智能化的改造
      </div>
    </div>
    <div class="work">
      <img src="@/assets/robot/r3.png" alt="知识图谱服务" />
      <p class="work-title">知识图谱服务</p>
      <div class="work-content">
        定制化服务，通过与用户的多轮交互，实现快递查询、订餐、医生预诊等多种服务类功能，满足不同业务需求，为更多的服务属性企业实现智能化的改造
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
onMounted(() => {});
</script>
<style scoped lang="less">
.work {
  background-color: #fff;
  width: 266px;
  height: 307px;
  padding: 60px;
  margin: 0 10px;
  @media screen and (max-width: 700px) {
    margin: 15px 0;
  }
}

.work img {
  width: 52px;
  height: 52px;
  margin-top: 10px;
}

.work-part {
  display: flex;
  justify-content: center;
  margin: 30px 0 80px 0;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.work-title {
  color: #021b64ff;
  font-size: 22px;
  text-align: center;
  padding: 30px 0;
}

.work-content {
  text-align: center;
  color: #021b64ff;
  opacity: 70%;
  font-size: 18px;
}
</style>
