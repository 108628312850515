<template>
  <div>
    <!-- 篮筐 -->
    <div class="flooter-top">
      <div class="left-center"></div>
      <div>
        <img
          class="left-img"
          src="@/assets/flooter/flooterImg.png"
          alt="免费试用"
        />
        <span class="left-text">立即注册免费试用</span>
      </div>
      <div class="left-center"></div>
      <div>
        <el-link
          href="https://regist.chat5188.com/"
          :underline="false"
          target="_blank"
        >
          <button class="right-button">立即获取试用账号</button>
        </el-link>
      </div>
      <div class="left-center"></div>
    </div>
  </div>
</template>
<style scoped lang="less">
.flooter-top {
  height: 160px;
  width: 100%;
  position: relative;
  background-color: rgba(0, 38, 154, 0.8);
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    height: 180px;
    flex-direction: column;
    align-items: center;
  }
}

.left-img {
  width: 46px;
  height: 46px;
  margin-top: -15px;
  margin-right: 20px;
  vertical-align: middle;

  @media screen and (max-width: 700px) {
    width: 30px;
    height: 30px;
    margin-top: -8px;
  }
}

.right-button {
  margin-top: 50px;
  width: 320px;
  height: 68px;
  background-color: #9de3ff;
  border: 1px solid #6cd6ff;
  background: -webkit-linear-gradient(top, #9de3ff, #6cd6ff);
  border-radius: 8px;
  color: #020f3b;
  font-size: 24px;
  font-family: PingFangSC-Medium, sans-serif !important;
  font-weight: bold;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 220px;
    height: 48px;
    margin-top: 0px;
    font-size: 18px;
  }
}

.left-center {
  width: 160px;

  @media screen and (max-width: 700px) {
    width: 180px;
  }
}

.left-text {
  color: #fff;
  font-size: 38px;
  line-height: 160px;
  white-space: nowrap;

  @media screen and (max-width: 700px) {
    font-size: 25px;
    line-height: 70px;
  }
}
</style>
